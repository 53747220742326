import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../styles/modalService.scss';

/** 
 * A modal dialog with Ok/Cancel buttons for closing. The cancel button is optional, 
 * To use modal import it from DataStoreContext: 
 * const { setInfoModal} = useContext(DataStoreContext);
 *  
 * Then use like this: //two button (ok/cancel) modal with customizable actions
 * setInfoModal({ 
            content: ['Are you sure you want to use a modal?'], 
            okCB: () => () => {console.log('This code runs on OK press');},
            cancelCB: () => () => {console.log('This code runs on Cancel press');}
    });

    Or like this: //one button (ok) modal with customizable action
    setInfoModal({ 
            content: ['Are you sure you want to use a modal?]', 
            okCB: () => () => {console.log('This code runs on OK press');}
    });

    Or like this: //one button (ok) modal which just closes on OK press
    setInfoModal({ 
            content: ['Are you sure you want to use a modal?'], 
    });
*/
export function InfoModal({text, header, infoModalOkCB, infoModalCancelCB, sidebarOpen, closeCB, customClass=null}) {
    let localText = text;
    if (!Array.isArray(localText)) localText = [text]; 
    let classes = 'info-modal';
    if (customClass) classes += ' '+customClass;
    if (sidebarOpen) classes += ' sidebar-open';


    const renderParagraph = (p) => {
        if (!customClass || !p) return p;
        if (p.includes('$*$')) {
            let parts = p.split('$*$');
            return (
                <><span className='timestamp'>{parts[0]}</span><span>{parts[1]}</span></>
            )
        }
        return p;
    }

    return (
        <Dialog
        open={true}
        onClose={closeCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes}
      >
        <DialogContent>
            {
                !header ? null :
                <div className="header">{header}</div>
            }
          <div id="alert-dialog-description">
          {localText.map((para, index) => (
            <p key={index}>
              {renderParagraph(para)}
            </p>
          ))}
          </div>
        </DialogContent>
        <DialogActions>
          
          { (infoModalCancelCB)? <Button onClick={infoModalCancelCB}>Avbryt</Button> : null }
          <Button onClick={infoModalOkCB} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }


  





