import React from 'react';
import { ReactComponent as Logo } from '../resources/logos/iskoll-logo-02.svg'

import '../styles/pulsatingLogo.scss'

export default function PulsatingLogo() {
  return (
    <div className="pulsating-logo">
        <Logo className="anim"/>
        <Logo className="static"/>
    </div>
  );
}