import React, { createContext, useRef, useState } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
    // Define refs and state here
    const mapRef = useRef();
    const mapsRef = useRef();
    const centerRef = useRef({ lat: 59.37902896408902, lng: 17.876854292049472 });
    const zoomRef = useRef(9);
    const fetchedReportsBounds = useRef();
    const mapDrawerStateRef = useRef('closed');
    const selectedMapReportRef = useRef(null);
    const clickedLakeReportIdRef = useRef(null);
    const userPositionRef = useRef();
    const [userPosition, setUserPosition] = useState(null);
    const [isPositioning, setIsPositioning] = useState(null);
    const [blockAutoPositioning, setBlockAutoPositioning] = useState(false);
    //const [clickedLakeReportId, setClickedLakeReportId] = useState(null);

    // The context value that will be passed to consumers
    const contextValue = {
        mapRef,
        mapsRef,
        centerRef,
        zoomRef,
        fetchedReportsBounds,
        mapDrawerStateRef,
        selectedMapReportRef,
        userPositionRef,
        isPositioning, setIsPositioning,
        userPosition, setUserPosition,
        blockAutoPositioning, setBlockAutoPositioning,
        clickedLakeReportIdRef
    };

    return (
        <MapContext.Provider value={contextValue}>
            {children}
        </MapContext.Provider>
    );
};

export default MapContext;
