import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
const options = {
  order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  lookupLocalStorage: 'lng'
}

//import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

//importing the folders with translation


const app_en = require('../resources/l10n/app_en.json');
const app_sv = require('../resources/l10n/app_sv.json');
const admin_en = require('../resources/l10n/admin_en.json');
const admin_sv = require('../resources/l10n/admin_sv.json');

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    fallbackLng: 'sv',
    supportedLngs: ['sv', 'en'],
    debug: false,
    returnEmptyString: false,
    resources: { 
      en: { 
        app: app_en, 
        admin: admin_en 
      },
      sv: { 
        app: app_sv, 
        admin: admin_sv 
      } 
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
