import sanitizeHtml from 'sanitize-html';
import allCounties from '../resources/counties.json'
import { parseISO, format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { ReactComponent as SkateIcon } from '../resources/activityicons/skridsko.svg';
import { ReactComponent as SkiIcon } from '../resources/activityicons/skidor.svg';
import { ReactComponent as SnowmobileIcon } from '../resources/activityicons/skoter.svg';
import { ReactComponent as FishingIcon } from '../resources/activityicons/isfiske.svg';
import { ReactComponent as HikingIcon } from '../resources/activityicons/promenad.svg';
import { ReactComponent as IceSailingIcon } from '../resources/activityicons/issegling.svg';
import { ReactComponent as IceReportIcon } from '../resources/activityicons/observation.svg';
import { ReactComponent as KayakIcon } from '../resources/activityicons/kajak.svg';
import { ReactComponent as SUPIcon } from '../resources/activityicons/sup.svg';
import { ReactComponent as UtekollIcon } from '../resources/logos/logoUk.svg';
import { Tooltip } from '@mui/material';


const HelperService = {};

HelperService.formatLengthSpan = function (span) {
    if (!span) return null;
    if (span[0] === span[1]) {
        return span[0]; 
    }
    return span[0] + '-' + span[1];
    //return span[0] + ' cm - ' + span[1];
};

HelperService.isAndroid = function () {
    // detect if it's an Android device
    const ua = navigator.userAgent.toLowerCase();
    console.log(ua);
    const isAndroid = ua.includes('android');
    return isAndroid;
    /* console.log(`The user is on an Android: ${isAndroid}`)
    const isIPhone = (navigator.userAgent.match(/iPhone/i)) ||(navigator.userAgent.match(/iPod/i))
    console.log(`The user is on an iPhone: ${isIPhone}`) */
}

HelperService.sleep = async function (seconds) {
    return new Promise(r => setTimeout(r, seconds*1000));
}

HelperService.isMyReport = function (report, me) {
    if (!me || !report) return false;
    return report.reporterRef === me.id;
}

/* Parse the rich text format we use in a User's biography text. Basically we just 
* remove all html tags except the ones we support.
*/
/* HelperService.parseRichText = function (richText) {
    if (!richText) return null;
   // console.log(richText);
    let supportedTags = ['h2', 'i', 'b', 'p', 'br'];

    for (let i = 0; i < supportedTags.length; i++) {
        const tag = supportedTags[i];
        //let RX1 = new RegExp(`<${tag}>`, "gi");
        // match opening tag with attributes, since we want to remove all attributes
        let RX1 = new RegExp(`<${tag}[^>]*>`, "gi");
        let RX2 = new RegExp(`</${tag}>`, "gi");
        richText = richText.replaceAll(RX1, `!!${tag}`);
        richText = richText.replaceAll(RX2, `--${tag}`);
    }
    //console.log('-----:');
    //console.log(richText);

    richText = richText.replaceAll(/<.*?>/gi, '');

    for (let i = 0; i < supportedTags.length; i++) {
        const tag = supportedTags[i];
        let RX1 = new RegExp(`!!${tag}`, "g");
        let RX2 = new RegExp(`--${tag}`, "g");
        richText = richText.replaceAll(RX1, `<${tag}>`);
        richText = richText.replaceAll(RX2, `</${tag}>`);
    }
   // console.log('*************');
   // console.log(richText);
    return richText;
} */

// sanitize html for user.bioText property to only allow the few markup elements we
// support. For better security we use this both on input and before rendering. 
HelperService.sanitizeRichText = (text) => {
    let allowedTags = ['h2', 'p', 'b', 'i', 'br'];
    text = sanitizeHtml(text, {allowedTags: allowedTags, allowedAttributes: {}});
    return text;
}

HelperService.areStringArraysDifferent = (a, b) => {
    if (!a && !b) return false;
    if ((!a && b) || (a && !b)) return true;
    if (a.length !== b.length) return true;
    for (let i = 0; i < a.length; i++) {
        if (!b.includes(a[i])) return true;
    }
    return false;
}

HelperService.arePeriodFiltersDifferent = (periodA, periodB) => {
    if (!periodA && !periodB) return false;

    let fromA = null; let toA = null; 
    if (periodA) fromA = periodA.from; 
    if (periodA) toA = periodA.to; 

    let fromB = null; let toB = null; 
    if (periodB) fromB = periodB.from; 
    if (periodB) toB = periodB.to; 

    return fromA !== fromB || toA !== toB;
}

/* The center of the bounds for a section of a map and the center of the entire Google Map in the viewport
   should be identical. But since Google Maps internally use different earth radiuses when calculating the 
   two there is a small difference, which depends on the zoom level. 
   This difference, in meters is given by:
   mapsRef.current.geometry.spherical.computeDistanceBetween(mapRef.current.getCenter(), bounds.getCenter())
   This method returns the diff for each zoom level. 
*/
HelperService.googleMapsErrorMargin = function (zoomLevel) {
    switch (zoomLevel) {
        case 1: return 19550000.0;
        case 2: return 8900000.0;
        case 3: return 670000.0;
        case 4: return 170000.0;
        case 5: return 47000.0;
        case 6: return 11000.0;
        case 7: return 2718.0;
        case 8: return 695.0;
        case 9: return 175.0;
        case 10: return 43.0;
        case 11: return 11.0;
        case 12: return 3.0;
        case 13: return 0.7;
        case 14: return 0.17;
        case 15: return 0.05;
        case 16: return 0.0104;
        case 17: return 0.003;
        case 18: return 0.0007;
        case 19: return 0.000161;
        case 20: return 0.000041;
        default: return 175.0;
    }
}

/* vitt, rött, orange, gult, grönt, blått, brunt, svart
*/
HelperService.getMedalLevel = function (numOfReports) {
    if (numOfReports < 3) return 0;
    if (numOfReports < 10) return 1; //white
    if (numOfReports < 20) return 2; //red
    if (numOfReports < 35) return 3; //orange
    if (numOfReports < 50) return 4;
    if (numOfReports < 75) return 5;
    if (numOfReports < 100) return 6;
    if (numOfReports < 140) return 7;
    if (numOfReports < 200) return 8;
    if (numOfReports < 20000) return 9;
}

HelperService.formatMedalLevel = function (level, lang) {
    switch (level) {
        case 1: return lang === 'en' ? 'white' : 'vit'
        case 2: return lang === 'en' ? 'red' : 'röd'
        case 3: return lang === 'en' ? 'orange' : 'orange'
        case 4: return lang === 'en' ? 'yellow' : 'gul'
        case 5: return lang === 'en' ? 'green' : 'grön'
        case 6: return lang === 'en' ? 'blue' : 'blå'
        case 7: return lang === 'en' ? 'brown' : 'brun'
        case 8: return lang === 'en' ? 'black' : 'svart'
        default: return null;

    }
};

HelperService.getReportCountForMedalLevel = function (level) {
    switch (level) {
        case 1: return 3
        case 2: return 10
        case 3: return 20
        case 4: return 35
        case 5: return 50
        case 6: return 75
        case 7: return 100
        case 8: return 140
        case 9: return 200
        default: return null;

    }
};

HelperService.isIceActivity = function (activity) {
    if (activity === 'KAYAK' || activity === 'SUP') return false;
    return true;
};

HelperService.getActivityIcon = function (activity) {
    switch (activity) {
        case 'SKATING': return <SkateIcon className="svg-activity-icon"/>
        case 'SKIING': return <SkiIcon className="svg-activity-icon"/>
        case 'SNOWMOBILE': return <SnowmobileIcon className="svg-activity-icon"/>
        case 'FISHING': return <FishingIcon className="svg-activity-icon"/>
        case 'HIKING': return <HikingIcon className="svg-activity-icon"/>
        case 'ICESAILING': return <IceSailingIcon className="svg-activity-icon"/>
        case 'ICE_REPORT': return <IceReportIcon className="svg-activity-icon"/>
        case 'KAYAK': return <KayakIcon className="svg-activity-icon"/>
        case 'SUP': return <SUPIcon className="svg-activity-icon"/>
        default: return <UtekollIcon/>

    }
};

HelperService.formatActivity = function (activity, lang) {
    switch (activity) {
        case 'SKATING': return lang === 'en' ? 'Ice Skating' : 'Skridskoåkning';
        case 'SKIING': return lang === 'en' ? 'Skiing' : 'Skidåkning'
        case 'SNOWMOBILE': return lang === 'en' ? 'Snowmobile' : 'Skoteråkning'
        case 'FISHING': return lang === 'en' ? 'Fishing' : 'Fiske'
        case 'HIKING': return lang === 'en' ? 'Walking' : 'Promenad'
        case 'ICESAILING': return lang === 'en' ? 'Ice Sailing' : 'Issegling'
        case 'ICE_BATH': return lang === 'en' ? 'Ice Bath' : 'Isbad'
        case 'ICE_REPORT': return lang === 'en' ? 'Ice Report' : 'Israpport'
        case 'KAYAK': return lang === 'en' ? 'Kayak' : 'Kajak'
        case 'SUP': return lang === 'en' ? 'SUP' : 'SUP-paddling'
        default: return lang === 'en' ? 'unknown' : 'okänd'

    }
};

HelperService.formatSmoothness = function (level, lang) {
    switch (level) {
        case 1: return lang === 'en' ? 'glossy' : 'glansis'
        case 2: return lang === 'en' ? 'matte' : 'matt'
        case 3: return lang === 'en' ? 'rough' : 'skrovlig'
        case 4: return lang === 'en' ? 'bumpy' : 'knölig'
        case 5: return lang === 'en' ? 'very bumpy' : 'mycket knölig'
        default: return lang === 'en' ? 'unknown' :  'okänd'
    }
};

HelperService.formatIceCracks = function (level) {
    switch (level) {
        case 1: return 'sprickfri'
        case 2: return 'nästan sprickfri'
        case 3: return 'vissa sprickor'
        case 4: return 'små och stora sprickor'
        case 5: return 'många stora sprickor'
        default: return 'okänd'
    }
};

HelperService.formatIceCracks2 = function (level, lang) {
    switch (level) {
        case 1: return lang === 'en' ? 'no cracks' :  'sprickfri'
        case 2: return lang === 'en' ? 'a few' :  'få små'
        case 3: return lang === 'en' ? 'some' :  'vissa'
        case 4: return lang === 'en' ? 'minor & major' :  'små och stora'
        case 5: return lang === 'en' ? 'many large' :  'många stora'
        default: return lang === 'en' ? 'unknown' :  'okänd'

    }
};

HelperService.formatDate = function (date) {
    let dt = parseISO(date);
    let x = format(dt, 'd MMM yyyy', { locale: sv });
    return x;
}

HelperService.formatTime = function (date) {
    let dt = parseISO(date);
    let x = format(dt, 'HH:mm', { locale: sv });
    return x;
}

HelperService.bearingText = function (bearing, lang) {
    switch (bearing) {
        case 'NO_BEARING': return lang === 'en' ? 'no bearing' :  'bär inte'
        case 'BAD_BEARING': return lang === 'en' ? 'weak bearing' :  'bär med tvekan'
        case 'REGULAR_BEARING': return lang === 'en' ? 'bear ok' :  'bär'
        case 'GOOD_BEARING': return lang === 'en' ? 'bear well' :  'bär väl'
        default: return lang === 'en' ? 'unknown' :  'okänd'
    }
};

HelperService.bearingLevel = function (bearing) {
    switch (bearing) {
        case 'NO_BEARING': return 5
        case 'BAD_BEARING': return 3
        case 'REGULAR_BEARING': return 1
        case 'GOOD_BEARING': return 1
        default: return 0
    }
};

HelperService.isSuperadmin = function (me) {
    return (me && me.role === 'superadmin');
};

HelperService.isUnregisteredUser = function (me) {
    return !me;
};

/* When a report has changed, e.g. by an added like, we add the updated data to all local copies
*/
HelperService.updateLocalReports = function (reportContext, reportId, data) {
    if (reportContext.flowReportsRef && reportContext.flowReportsRef.current) {
        let reportIndex = reportContext.flowReportsRef.current.findIndex(r => r.id === reportId);
        if (reportIndex >= 0) reportContext.flowReportsRef.current[reportIndex] = {...reportContext.flowReportsRef.current[reportIndex], ...data};
    }
    
    if (reportContext.mapReportsRef && reportContext.mapReportsRef.current) {
        let reportIndex = reportContext.mapReportsRef.current.findIndex(r => r.id === reportId);
        if (reportIndex >= 0) reportContext.mapReportsRef.current[reportIndex] = {...reportContext.mapReportsRef.current[reportIndex], ...data};
    } 
    
    if (reportContext.myReportsRef && reportContext.myReportsRef.current) {
        let reportIndex = reportContext.myReportsRef.current.findIndex(r => r.id === reportId);
        if (reportIndex >= 0) reportContext.myReportsRef.current[reportIndex] = {...reportContext.myReportsRef.current[reportIndex], ...data};
    }
};

HelperService.localStorageItem = function (item) {
    if (window && window.localStorage) return window.localStorage.getItem(item);
    return null;
};

HelperService.getCountyMunicipalityName = function (id) {
    const countries = ['SE','NO'];
    for (let i = 0; i < countries.length; i++) {
        const counties = allCounties[countries[i]];
        for (let c = 0; c < counties.length; c++) {
            const county = counties[c];
            if (county.id === id) return county.name;
            if (county.municipalities) {
                for (let m = 0; m < county.municipalities.length; m++) {
                    const munic = county.municipalities[m];
                    if (munic.id === id) return munic.name;
                }
            }
        }       
    }
}

HelperService.openUrl = (url, isFlutterApp, sendFlutterNotification) => {
    if (isFlutterApp) {
        let payload = '{"command": "LAUNCH_URL", "url":"' + url + '"}';
        sendFlutterNotification(payload);
    }
    else {
        window.open(url);
    }
  };

HelperService.openSocialApp = (url, fallbackUrl, isFlutterApp, sendFlutterNotification) => {
    if (isFlutterApp) {
        let payload = '{"command": "LAUNCH_SOCIAL_APP", "url":"' + url + '", "fallbackUrl":"' + fallbackUrl + '"}';
        sendFlutterNotification(payload);
    }
    else {
        window.open(fallbackUrl);
    }
  }; 

  HelperService.processAndSetTopics = (serverTopics, setMyTopics) => {
    let topics = [];
        if (serverTopics && serverTopics.topics) {
            let topicKeys = Object.keys(serverTopics.topics);
            topics = HelperService.getListOfTopics(topicKeys);            
        }
        
        setMyTopics(topics);
}  

HelperService.getListOfTopics = (topicKeys) => {
    let topics = [];
    if (!topicKeys) return topics;
    for (let i = 0; i < topicKeys.length; i++) {
        let parts = topicKeys[i].split('__');
        if (parts[0] === 'COUNTY' || parts[0] === 'MUNICIPALITY') {
            const name = HelperService.getCountyMunicipalityName(parts[1]);
            topics.push({name:name, id: parts[1], frequency: parts[2], type: parts[0], topic: topicKeys[i]});
        }
        else if (parts[0] === 'WATER') {
            // TODO
            console.log('TODO: Handle water topic');
        }
        else {
            topics.push({name:null, frequency: parts[1], type: parts[0], topic: topicKeys[i]});
        }
    }
    return topics;
}  

HelperService.renderShortenedString = (name, length, showTooltip=true) => {
    let fullName = name;
    if (!length) length = 16;
    if (name && name.length > length) name = name.substring(0, length) + '...';
    if (showTooltip) {
        return ( 
            <Tooltip title={fullName} placement="top" arrow><span>{name}</span></Tooltip>
        );
    }
    return (<span>{name}</span>);
    
}
  
HelperService.isIskoll = () => {
    return false;
    //return (window && window.location && window.location.href && window.location.href.includes('iskoll'));
};   

export default HelperService;

export const CONST = {
    BIO_TEXT_MAX_LENGTH: 5000,
    USER_NAME_MAX_LENGTH: 50,
    COMMENT_MAX_LENGTH: 300,
    //REPORT_IMAGE_BUCKET: 'se_iskoll_images',
    REPORT_IMAGE_BUCKET: 'se_iskoll_report_images',
    REPORT_THUMB_IMAGE_BUCKET: 'se_iskoll_image_thumbs',
    THE_THEME: {
        palette: {
            background: {
                default: '#fbfbfb',
            },
            primary: {
              main: '#5e7093',
              darker: '#475570',
            },
            neutral: {
              main: '#64748B',
              contrastText: '#fff',
            },
          },
      },
};