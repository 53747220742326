import { getAuth, onAuthStateChanged } from "firebase/auth";
import AuthenticationService from "./authenticationService";


const ApiService = {};

let SERVICE_URL = 'https://iskoll.ew.r.appspot.com';
//SERVICE_URL = 'http://localhost:8080';

ApiService.sendFlutterNotification = null;

ApiService.accessToken = null;

let USE_TEST_REPORTS = (window.localStorage && window.localStorage.getItem('useTestData') === 'ok'); 

ApiService.PROFILE_BUCKET = 'https://storage.googleapis.com/se_iskoll_profile_images/';


ApiService.refreshToken = async function () {
    //console.log('RENEWING THE ACCESS TOKEN');
    const auth = getAuth();
    //console.log(auth.currentUser);
    if (!auth.currentUser) {
        return new Promise((resolve, reject) => {
            onAuthStateChanged(auth, AuthenticationService.handleAuthStateChange(async ()=>{
                //console.log('NEW ACCESS TOKEN!!');    
                resolve();
            }, ApiService.sendFlutterNotification));
        });
    }
    else {
        const user = auth.currentUser;
        const token = await user.getIdToken();
        ApiService.accessToken = token;
    }
    
}

/* Each API call shall use the callApi function below. It both calls the api method (typically 
    one of the functions further down) and refresh the id/bearer token when needed (once per hour).
    'func': The function to call,  e.g. ApiService.getCounters
    'args': Array of the function arguments
    'name': Name of function, for debug only
    
    Example usage : let users = await ApiService.callApi(ApiService.getUsers, [BATCH_SIZE, nextPageCursor, userOrderBy, userOrder, filter]);
*/
ApiService.callApi = async function(func, args, name=null) {
    let result = {};
    if (!args) args= [];
    try {
        /*if (name) { 
            //just for testing 
            console.log('CALL API: ', name);
            if (name === 'getMe') {
                const error = new Error("message")
                error.code = "TOKEN-EXPIRED"
                throw error;
            } 
        }*/
        
        result = await func(...args );   
    } catch (error) {
        if (error.code === 'TOKEN-EXPIRED') {
            await ApiService.refreshToken();
            result = await func( ...args );
        }
        else {
            console.log('ERROR DURING CALL API: ', name);
            console.log(JSON.stringify(error));
            throw error;
        }
    }
    //if (name) console.timeEnd(name);
    return result;
}

  /* Does not throw an error if no user (i.e. unreg. user) it returns null 
   * payload: {fcmToken: fcmToken}, where FCM token is null for the web
   * user, set to the device notification token if it is an app user who approved to notifications.
  */
  ApiService.getMe = async function (payload) {   
    let url = SERVICE_URL + '/api/user/me';

    try {
        const response = await fetch(url, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache',
            headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
            body: JSON.stringify(payload) 
          });

    
        let user = await response.json();
        // if anonymous user the response is an empty json object, if so we set user to null
        if (user && Object.keys(user).length === 0) user = null;

        if (!response.ok) {
            // eslint-disable-next-line
            if (user.code === 'TOKEN-EXPIRED') throw {code: 'TOKEN-EXPIRED'};
            // eslint-disable-next-line
            return null;
        }
        return user;    
    } catch (error) {
        if (error.code === 'TOKEN-EXPIRED') throw error;
        console.log(error);
        return null;
    }

    
  }; 

ApiService.getCounters = async function () {    
    let url = SERVICE_URL + '/api/counters';

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });

    const counters = await response.json();
    if (!response.ok) {
        console.log(counters.message);
          throw counters;
    }
    return counters;
  };

ApiService.getUser = async function (uid) {    
    let url = SERVICE_URL + '/api/user/' + uid;

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };


  
  ApiService.updateMe = async function (data) {    
    let url = SERVICE_URL + '/api/user/me/update';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };

  ApiService.updateFcmToken = async function (fcmToken) {    
    let url = SERVICE_URL + '/api/user/me/update_fcm_token';
    
    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify({fcmToken:fcmToken}) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };

  ApiService.deleteMe = async function () {    
    let url = SERVICE_URL + '/api/user/me/delete';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'},  
      });

      if (response.status !== 204) {
            throw new Error('user not deleted');
      }
  };  

  ApiService.getTopics = async function (fcmToken) {    
    let url = SERVICE_URL + '/api/admin/get_topics/' + fcmToken;

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };


  ApiService.getMyTopics = async function (fcmToken) {    
    let url = SERVICE_URL + '/api/user/me/topics/' + fcmToken;

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };

  /**
   * 
   * @param {*} data {"topic": "WATER__WA15921303__DIRECT", "fcmToken": "xyz"}
   * @returns 
   */
  ApiService.subscribeMeToTopic = async function (data) {    
    let url = SERVICE_URL + '/api/user/me/subscribe_to_topic';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };  

  /**
   * 
   * @param {*} data {"topic": "WATER__WA15921303__DIRECT", "fcmToken": "xyz"}
   * @returns 
   */
  ApiService.unsubscribeMeFromTopic = async function (data) {    
    let url = SERVICE_URL + '/api/user/me/unsubscribe_from_topic';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  }; 
  
  /**
   * 
   * @param {*} data {"fcmToken": "xyz"}
   * @returns 
   */
  ApiService.unsubscribeMeFromAllTopics = async function (data) {    
    let url = SERVICE_URL + '/api/user/me/unsubscribe_from_all_topics';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  }; 

  ApiService.setProfilePicture = async function (formData) {    
    let url = SERVICE_URL + '/api/user/me/uploadProfilePicture';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
          body: formData 
      });

    const image = await response.json();
    if (!response.ok) {
        console.log(image.message);
          throw image;
    }
    return image;
  };
  
  ApiService.deleteProfilePicture = async function (imageUrl) {    
    let url = SERVICE_URL + '/api/user/me/deleteProfilePicture';

    return await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify({url: imageUrl}) 
      });
  };


  
  ApiService.getUsers = async function (limit, nextPageToken, orderBy, order, filter) {    
    let url = SERVICE_URL + '/api/users?';
    if (limit) url += 'limit='+limit;
    if (nextPageToken) url += '&nextPageToken='+nextPageToken;
    if (orderBy) url += '&orderBy='+orderBy;
    if (order) url += '&order='+order;
    if (filter) url += '&filter='+filter;
    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });
    const result = await response.json();
    if (!response.ok) throw result;
    return result;
  };

  ApiService.updateUser = async function (id, data) {    
    let url = SERVICE_URL + '/api/user/' + id + '/update';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };

  ApiService.setUserRole = async function (id, data) {    
    let url = SERVICE_URL + '/api/user/' + id + '/setRole';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };

  ApiService.refreshReportCounter = async function (uid) {    
    let url = SERVICE_URL + '/api/user/' + uid + '/refresh_report_counter';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };

  ApiService.getAuthenticationToken = async function (uid) {    
    let url = SERVICE_URL + '/api/user/' + uid + '/get_authentication_token';

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };
  

  ApiService.deleteUsers = async function (uids) {    
    let url = SERVICE_URL + '/api/users/delete';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify({userIds: uids}) 
      });

    const user = await response.json();
    if (!response.ok) {
        console.log(user.message);
          throw user;
    }
    return user;
  };

  ApiService.deleteReport = async function (uid) {    
    let url = SERVICE_URL + '/api/report/' + uid + '/delete';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'},  
      });

    const result = await response.json();  

    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };

  ApiService.deleteReports = async function (uids) {    
    let url = SERVICE_URL + '/api/reports/delete';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify({reportIds: uids}) 
      });

    const report = await response.json();
    if (!response.ok) {
        console.log(report.message);
          throw report;
    }
    return report;
  };

  ApiService.getReport = async function (reportId) {    
    let url = SERVICE_URL + '/api/report/'+reportId;
    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
    });
    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };

  ApiService.getReports = async function (limit, nextPageToken, orderBy, order, filter) {    
    let url = SERVICE_URL + '/api/reports?';
    if (limit) url += 'limit='+limit;
    if (nextPageToken) url += '&nextPageToken='+nextPageToken;
    if (orderBy) url += '&orderBy='+orderBy;
    if (order) url += '&order='+order;
    if (filter) url += '&filter='+filter;
    if (USE_TEST_REPORTS) url += '&useTestReports=true';
    
    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        //headers: {'Authorization': 'Bearer '+ApiService.accessToken},
    });
    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };

  ApiService.updateReport = async function (id, data) {    
    let url = SERVICE_URL + '/api/report/' + id + '/update';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken, 'Content-Type': 'application/json'}, 
          body: JSON.stringify(data) 
      });

    const report = await response.json();
    if (!response.ok) {
        console.log(report.message);
          throw report;
    }
    return report;
  };  

/* Get all report in a circle defined by center, radius. This method is currently not used
   in the app. 
*/  
ApiService.getReportsAround = async function (center, radius) {
  let lat = center.lat();
  let lng = center.lng();
  let radKm = radius / 1000; 
  
  let url = SERVICE_URL + '/api/reportsAround';
  url += '?lat='+lat;
  url += '&lng='+lng;
  url += '&radius='+radKm;

  const response = await fetch(url);
  const reports = await response.json();
  if (!response.ok) {
      console.log(reports.message);
        throw reports;
  }
  return reports;
};

/**
 * Get all reports in a box defined by sw and ne corners. 
 * Coordinates are https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng
 * @param {*} sw LatLng
 * @param {*} ne LatLng
 * @returns 
 */

ApiService.getReportsInBox = async function (sw, ne, filter) {
    let swLat = sw.lat();
    let swLng = sw.lng();
    let neLat = ne.lat();
    let neLng = ne.lng(); 
    
    let url = SERVICE_URL + '/api/reportsInBox';
    url += '?swLat='+swLat;
    url += '&swLng='+swLng;
    url += '&neLat='+neLat;
    url += '&neLng='+neLng;

    if (filter) url += '&filter='+filter;
    if (USE_TEST_REPORTS) url += '&useTestReports=true';

    const response = await fetch(url);
    const reports = await response.json();
    if (!response.ok) {
        console.log(reports.message);
          throw reports;
    }
    return reports;
    
  
  
  };

  ApiService.createReport = async function (reportData) {
    
    let url = SERVICE_URL + '/api/addReport';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ApiService.accessToken,
        },
        body: JSON.stringify(reportData) 
      });

    const report = await response.json();
    if (!response.ok) {
        console.log(report.message);
          throw report;
    }
    return report;
  }; 

  ApiService.addMediaToReport = async function (reportId, fileData) {
    
    let url = SERVICE_URL + '/api/report/' + reportId + '/media/add';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Authorization': 'Bearer '+ApiService.accessToken,
        },
        body: fileData 
      });

    const report = await response.json();
    if (!response.ok) {
        console.log(report.message);
          throw report;
    }
    return report;
  }; 

  ApiService.getLikes = async function (reportId, limit, nextPageToken, orderBy, order, filter) {       
       let url = SERVICE_URL + '/api/report/' + reportId + '/likes?';
       if (limit) url += 'limit='+limit;
       if (nextPageToken) url += '&nextPageToken='+nextPageToken;
       if (orderBy) url += '&orderBy='+orderBy;
       if (order) url += '&order='+order;
       if (filter) url += '&filter='+filter;
       const response = await fetch(url, {
           method: 'GET', 
           mode: 'cors', 
           cache: 'no-cache' 
         });
       const result = await response.json();
       if (!response.ok) {
           console.log(result.message);
             throw result;
       }
       return result;
     };

  ApiService.addLike = async function (reportId) {
    
    let url = SERVICE_URL + '/api/report/' +reportId +'/like/create';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer '+ApiService.accessToken,
        },
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };

  ApiService.removeLike = async function (reportId, likeId) {    
    let url = SERVICE_URL + '/api/report/' +reportId +'/like/' + likeId + '/delete';

    return await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer '+ApiService.accessToken,
        },
      });
  };  


  ApiService.getComments = async function (reportId, limit, nextPageToken, orderBy, order, filter) {       
    let url = SERVICE_URL + '/api/report/' + reportId + '/comments?';
    if (limit) url += 'limit='+limit;
    if (nextPageToken) url += '&nextPageToken='+nextPageToken;
    if (orderBy) url += '&orderBy='+orderBy;
    if (order) url += '&order='+order;
    if (filter) url += '&filter='+filter;
    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache' 
      });
    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };

  /**
   * 
   * commentData: {text: xxx}
   */
  ApiService.addComment = async function (reportId, commentData) {
    
    let url = SERVICE_URL + '/api/report/' +reportId +'/comment/create';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer '+ApiService.accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(commentData)
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };  

  /**
   * 
   * commentData: {text: xxx}
   */
  ApiService.updateComment = async function (reportId, commentId, commentData) {
    
    let url = SERVICE_URL + '/api/report/' +reportId +'/comment/' +commentId + '/update';


    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer '+ApiService.accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(commentData)
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };  

  ApiService.deleteComment = async function (reportId, commentId) {
    
    let url = SERVICE_URL + '/api/report/' +reportId +'/comment/' +commentId + '/delete';

    return await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer '+ApiService.accessToken,
        },
      });
  };  


  ApiService.registerUser = async function (uid, registrationData) {
    let url = SERVICE_URL + '/api/user/register/' + uid;

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(registrationData) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  }; 

  ApiService.reserveDisplayName = async function (displayName) {
    let url = SERVICE_URL + '/api/user/reserveDisplayName';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({displayName: displayName}) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result.message);
          throw result;
    }
    return result;
  };
  
  ApiService.forgotPassword = async function (email) {
    let url = SERVICE_URL + '/api/user/forgotpw';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email}) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };

  /* Sends an email to the user with email 'email', the email contains a link which
     verifies the email 
  */
  ApiService.confirmEmail = async function (email) {
    let url = SERVICE_URL + '/api/user/confirmemail';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email}) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };

  /* Superadmin can manually confirm an email for a user 
  */
     ApiService.manuallyConfirmEmail = async function (uid) {
        let url = SERVICE_URL + '/api/user/manually_confirm_email';
    
        const response = await fetch(url, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ApiService.accessToken,
              },
            body: JSON.stringify({uid: uid}) 
          });
    
        const result = await response.json();
        if (!response.ok) {
            console.log(result);
              throw result;
        }
        return result;
      };  

  /* Superadmin can manually confirm an email for a user 
  */
  ApiService.changePasswordForUser = async function (uid, password) {
    let url = SERVICE_URL + '/api/user/manually_change_password';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ApiService.accessToken,
          },
        body: JSON.stringify({uid: uid, password: password}) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };  

  /* Superadmin can manually confirm an email for a user 
  */
  ApiService.generateForgotPasswordLink = async function (uid) {
    let url = SERVICE_URL + '/api/user/forgot_password_link';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ApiService.accessToken,
          },
        body: JSON.stringify({uid: uid}) 
      });

    const result = await response.json();
    if (!response.ok) {
        console.log(result);
          throw result;
    }
    return result;
  };   

  ApiService.createCustomWater = async function (waterData) {
    let url = SERVICE_URL + '/api/geo/water/registerCustom';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(waterData) 
      });

    const water = await response.json();
    if (!response.ok) {
        console.log(water.message);
          throw water;
    }
    return water;
  }; 


  
  ApiService.getInfoAtCoord = async function (lat, lng) {
    
    let url = SERVICE_URL + '/api/geo/coordinateInfo?lat=' + lat + '&lng=' + lng;

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache', 
      });

    const coordInfo = await response.json();
    if (!response.ok) {
        console.log(coordInfo.message);
          throw coordInfo;
    }
    return coordInfo;
  }; 

  ApiService.getWaters = async function (orderBy, limit) {
    
    let url = SERVICE_URL + '/api/geo/waters?orderBy=' + orderBy + '&limit=' + limit;

    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache', 
      });

    const waters = await response.json();
    if (!response.ok) {
        console.log(waters.message);
          throw waters;
    }
    return waters;
  }; 
  
  ApiService.getLakeInfo = async function (lat, lng) {
    let url = 'https://viss.lansstyrelsen.se/api?method=coordinateinfo&apikey=a91f01ed5577b3f9e68fb09d50ad6bea&format=Json&x=';
    url += lat + '&y=' + lng;
    url += '&radius=0&coordinateformat=WGS84&managementcycleidentifier=2';
    const response = await fetch(url);
    const lake = await response.json();
    return lake;
  };

  
  /* Send a notification to a single device identified by fcmToken. Payload:
     {fcmToken, messageData, reportId}. If messageData is provided a text notice is sent, 
     if reportId is provided a report notice is sent.
     messageData = {titleText, bodyText, imageUrl}  
  */
  ApiService.sendNotification = async function (payload) {
    let url = SERVICE_URL + '/api/admin/send_notification';

    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ApiService.accessToken,
        },
        body: JSON.stringify(payload) 
      });

    const water = await response.json();
    if (!response.ok) {
        console.log(water.message);
          throw water;
    }
    return water;
  };   

  ApiService.getTopReporters = async function (limit, nextPageToken) {    
    let url = SERVICE_URL + '/api/medal/top_reporters?';
    if (limit) url += 'limit='+limit;
    if (nextPageToken) url += '&nextPageToken='+nextPageToken;
    
    const response = await fetch(url, {
        method: 'GET', 
        mode: 'cors', 
        cache: 'no-cache',
        headers: {'Authorization': 'Bearer '+ApiService.accessToken}, 
      });
    const result = await response.json();
    if (!response.ok) throw result;
    return result;
  };  

export default ApiService;