import React, { createContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    // Define states here
    const [activityFilter, setActivityFilter] = useState(null);
    const [periodFilter, setPeriodFilter] = useState(null);
    const [quickFilter, setQuickFilter] = useState(null);
    const [previousActivityFilter, setPreviousActivityFilter] = useState(null);
    const [previousPeriodFilter, setPreviousPeriodFilter] = useState(null);
    const [previousQuickFilter, setPreviousQuickFilter] = useState(null);

    // Context value
    const filterStore = {
        activityFilter, setActivityFilter, 
        periodFilter, setPeriodFilter, 
        quickFilter, setQuickFilter,
        previousQuickFilter, setPreviousQuickFilter,
        previousActivityFilter, setPreviousActivityFilter, 
        previousPeriodFilter, setPreviousPeriodFilter
    };

    return (
        <FilterContext.Provider value={filterStore}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterContext;
