import React, {useEffect, useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import StoreContext from '../services/storeContext'
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoginIcon from '@mui/icons-material/Login';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
//import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import GroupIcon from '@mui/icons-material/Group';
import HelpIcon from '@mui/icons-material/Help';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import GrainIcon from '@mui/icons-material/Grain';
import ReplayIcon from '@mui/icons-material/Replay';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Link, useLocation} from "react-router-dom";
import { ReactComponent as Logo } from '../resources/logos/iskoll-logo-02.svg'
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ApiService from '../services/apiService';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UtekollText } from '../resources/logos/textlogoUk.svg'
import UtekollLogo from '../resources/logos/logoUk256.png'
import { ReactComponent as MedalIcon } from '../resources/activityicons/medalj.svg';
import HelperService from '../services/helperService';
import '../styles/sideBar.scss'

const drawerWidth = 240;
const VERSION = '0.56.6';

function SideBar({window, open, toggleDrawerCB, onboardingCB, devMode, devModeCB}) {
    const { t, i18n } = useTranslation('app');
    let { me } = useContext(StoreContext);
    let { isFlutterApp, flutterVersion} = useContext(StoreContext);
    const ute = !HelperService.isIskoll();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    useEffect(() => {    
    //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [me]);

    const handleDrawerToggle = () => {
        //setMobileOpen(!mobileOpen);
        toggleDrawerCB();
    };

    //let { me, fcmToken } = useContext(StoreContext);
    

    const location = useLocation();

    const matchPath = (pathFragment) => {
        if (pathFragment.length === 1) {
            return  (location.pathname === '/') ;
        } 
        else {
            return (location.pathname && location.pathname.includes(pathFragment));
        }
    };

    const inactivateLink = (event, path) => {
        if (matchPath(path)) event.preventDefault();
        if (isMobile) toggleDrawerCB();
    }

     const reloadApp =  () => {
        window.location.reload();
    } 

    const changeLanguage = async (lang) => {
        i18n.changeLanguage(lang);
        /* var userSettingsCopy = cloneDeep(userSettings);
        userSettingsCopy.language = lang;
        setUserSettings(userSettingsCopy);
 */
        /* if (userMe) {
            let updatedUser = await Api.updateUserMe({preferredLanguage: lang});
            updatedUser.usage = userMe.usage;
            if (updatedUser) setUserMe(updatedUser);
        } */
    }


    const renderDevModeFeatures = () => {
        return (
            <>
                <ListSubheader component="div" id="nested-list-subheader">
                    Developer Mode
                </ListSubheader>
                <div className='side-menu-item' onClick={() => {devModeCB('SHOW_LOG')}}>
                    <ListItem button key='dashboard'>
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary='show log' />
                    </ListItem>
                </div>
                <div className='side-menu-item' onClick={() => {devModeCB('DELETE_LOG')}}>
                    <ListItem button key='dashboard'>
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary='clear log' />
                    </ListItem>
                </div>
            </>
        );
    }

    const renderAdminFeatures = () => {
        return (
            <>
                <ListSubheader component="div" id="nested-list-subheader">
                    Administratörsfunktioner
                </ListSubheader>
                <Link to="/dashboard" onClick={(event) => inactivateLink(event, 'dashboard')}>
                    <ListItem button key='dashboard' selected={matchPath('dashboard')}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary='dashboard' />
                    </ListItem>
                </Link>
                <Link to="/users" onClick={(event) => inactivateLink(event, 'users')}>
                    <ListItem button key='users' selected={matchPath('users')}>
                        <ListItemIcon><GroupIcon /></ListItemIcon>
                        <ListItemText primary='användare' />
                    </ListItem>
                </Link>
                <Link to="/allreports" onClick={(event) => inactivateLink(event, 'allreports')}>
                    <ListItem button key='users' selected={matchPath('allreports')}>
                        <ListItemIcon><GrainIcon /></ListItemIcon>
                        <ListItemText primary={t('ice reports')} />
                    </ListItem>
                </Link>

            </>
        );
    }

    const renderLanguagePicker = () => {
        return (
            <>
                <ListSubheader component="div" id="nested-list-subheader">
                            {t('language')}
                        </ListSubheader>

                    <div className="language-block">
                        <div className="lang-row" onClick={()=>{changeLanguage('en')}}>
                            <span className="flag flag-uk"/>
                            {i18n.language === 'en' ?
                                <span style={{fontWeight:'bold'}}>English</span> :
                                <span>English</span>
                            }
                        </div>
                        <div className="lang-row" onClick={()=>{changeLanguage('sv')}}>
                            <span className="flag flag-se"/>
                            {i18n.language === 'sv' ?
                                <span style={{fontWeight:'bold'}}>Svenska</span> :
                                <span>Svenska</span>
                            }
                        </div>
                    </div>
            </>
        );
    }

    /* const sendTopic = (topic) => {
        let payload = '{"command": "SET_TOPIC", "topic":"' + topic + '"}';
        if (isFlutterApp) sendFlutterNotification(payload);
    } */

    const drawer = (
        <>
            <div className="drawer-header">
                {ute ? <div className="utekolllogo"><img alt="" src={UtekollLogo} style={{ width: 'inherit', height: 'inherit' }} /></div> :
                    <div className="logo-sq"></div>
                }

                {ute ? <div className="utekolltext"><UtekollText /></div> :
                    <div className="logo"></div>
                }

                <IconButton onClick={toggleDrawerCB} size="large">
                    {/*theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />*/}
                    {true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />

            <List className="side-bar">
                <div>
                    {me ?
                        <Link className={matchPath('profile') ? "profile-item selected" : "profile-item"} to="/profile" onClick={(event) => inactivateLink(event, 'profile')}>
                            <ListItem button key='profile' selected={false}>
                                {me.profileImage ?
                                    <ListItemIcon><img className="profile-image" src={ApiService.PROFILE_BUCKET + me.profileImage} alt="profile"></img></ListItemIcon> :
                                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                }
                                <div className="name-block">
                                    <ListItemText primary={t('profile')} />
                                    <div className="profile-name">{me.displayName ? me.displayName : 'no displayname'}</div>
                                </div>
                            </ListItem>

                        </Link>
                        :
                        <>
                            <Link to="" onClick={() => onboardingCB('REGISTRATION')}>
                                <ListItem button key='flow' selected={matchPath('flow')}>
                                    <ListItemIcon><LockOpenIcon /></ListItemIcon>
                                    <ListItemText primary={t('sign up')} />
                                </ListItem>
                            </Link>
                            <Link to="" onClick={() => onboardingCB('LOGIN')}>
                                <ListItem button key='flow' selected={matchPath('flow')}>
                                    <ListItemIcon><LoginIcon /></ListItemIcon>
                                    <ListItemText primary={t('log in')} />
                                </ListItem>
                            </Link>
                            <Divider style={{ margin: '5px' }} />
                        </>
                    }

                    {/*<<Link to="/" onClick={(event) => inactivateLink(event, '/')}>
                        <ListItem button key='home' selected={matchPath('/')}>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary='hem' />
                        </ListItem>
                    </Link>
                    Link to="/reports" onClick={(event) => inactivateLink(event, 'reports')}>
                        <ListItem button key='reports' selected={matchPath('reports')}>
                            <ListItemIcon><Logo className="logo-icon" /></ListItemIcon>
                            <ListItemText primary='israpporter' />
                        </ListItem>
                    </Link> */}
                    <Link to="/" onClick={(event) => inactivateLink(event, '/')}>
                        <ListItem button key='flow' selected={matchPath('/')}>
                            <ListItemIcon>
                                {ute ? <div className="logo-icon"><img alt="" src={UtekollLogo} style={{ width: 'inherit', height: 'inherit' }} /></div> :
                                    <Logo className="logo-icon" />
                                }

                            </ListItemIcon>
                            <ListItemText primary={t('flow')} />
                        </ListItem>
                    </Link>
                    <Link to="/map" onClick={(event) => inactivateLink(event, 'map')}>
                        <ListItem button key='map' selected={matchPath('map')}>
                            <ListItemIcon><MapIcon /></ListItemIcon>
                            <ListItemText primary={t('map')} />
                        </ListItem>
                    </Link>
                    <Link to="/medals" onClick={(event) => inactivateLink(event, 'medals')}>
                        <ListItem button key='map' selected={matchPath('medals')}>
                            <ListItemIcon className="medal-icon"><MedalIcon /></ListItemIcon>
                            <ListItemText primary={t('medalists')} />
                            <div className='new-label'>{t('new')}</div>
                        </ListItem>
                    </Link>
                    <Link to="/faq" onClick={(event) => inactivateLink(event, 'faq')}>
                        <ListItem button key='about' selected={matchPath('faq')}>
                            <ListItemIcon><HelpIcon /></ListItemIcon>
                            <ListItemText primary={t('faq')} />
                        </ListItem>
                    </Link>
                    <Link to="/about" onClick={(event) => inactivateLink(event, 'about')}>
                        <ListItem button key='about' selected={matchPath('about')}>
                            <ListItemIcon><InfoIcon /></ListItemIcon>
                            {
                                HelperService.isIskoll() ?
                                    <ListItemText primary={t('about iskoll')} /> : <ListItemText primary={t('about utekoll')} />
                            }

                        </ListItem>
                    </Link>

                    {isFlutterApp ? null : renderLanguagePicker()}

                    {/* <div onClick={()=>{sendTopic('test_topic')}}>HH: {fcmToken}</div>  */}

                    {me && (me.role === 'admin' || me.role === 'superadmin') ? renderAdminFeatures() : null}

                    {devMode ? renderDevModeFeatures() : null}
                    <div className="version" onClick={() => reloadApp()}>
                        {!flutterVersion ? null :
                            <span>{flutterVersion} /&nbsp;</span>
                        }
                        <span style={{ display: 'flex', alignItems: 'center' }}>{VERSION}<ReplayIcon /></span>
                    </div>

                </div>
            </List>
        </>
    );

    //const container = window !== undefined ? () => window().document.body : undefined;
    const container = undefined;   
    
    return (
    <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="persistent"
          
          sx={{ display: { xs: 'none', sm: 'block' }, width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', }, }}
          open={open}
          onClose={handleDrawerToggle}
        >
          {drawer}
        </Drawer>
      </Box>
    );
} 

//export default SideBar;
const MemoizedSideBar = React.memo(SideBar);
export default MemoizedSideBar;