import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './services/i18n';
import reportWebVitals from './reportWebVitals';
//import firebase from "firebase/app";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

import { BrowserRouter as Router} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MapProvider } from './services/mapContext';
import { StoreProvider } from './services/storeContext';
import { UsersProvider } from './services/usersContext';
import { ReportsProvider } from './services/reportsContext';
import { FilterProvider } from './services/filterContext';
import { FlowViewProvider } from './services/flowViewContext';
import { ProfileViewProvider } from './services/profileViewContext';
import { MedalViewProvider } from './services/medalViewContext';


//firebase.initializeApp({
const app = initializeApp({
    apiKey: "AIzaSyC72vcMZeDkhMcS9s_AsYn5orGRHUyvlzA",
    authDomain: "iskoll.firebaseapp.com",
    projectId: "iskoll",
    storageBucket: "iskoll.appspot.com",
    messagingSenderId: "930445666582",
    appId: "1:930445666582:web:7ff0797631066cecb30837",
    measurementId: "G-9DRWLZ5QH4"
  });
  const analytics = getAnalytics(app);

  window.flutterApi = {
    setNotificationToken: null,
    setNotificationLevel: null,
    showReport: null,
    updateCurrentPosition: null,
    showError: null,
    setBearerToken: null,
    setSignedinUser: null,
    justLog: null,
    setLanguage: null,
    triggerRedraw: null,
    setFlutterVersion: null,
    setFlutterPlatform: null,
    setFilePickerResult: null,
    };

  initFacebookSdk().then(startApp);

/* function startApp() { 
    ReactDOM.render(
        <Router>
                <App firebaseAnalytics={analytics}/>
            </Router>,
    document.getElementById('root')
    );
}  */

/* function startApp() { 
    ReactDOM.render(
    <React.StrictMode>
        <AppWrapper/>
    </React.StrictMode>,
    document.getElementById('root')
    );
} */

function startApp() {
    const container = document.getElementById('root');
    const root = createRoot(container); // No need for '!' since you are not using TypeScript
    root.render(
      <React.StrictMode>
        <AppWrapper />
      </React.StrictMode>
    );
  } 


const theme = createTheme();

const provider = (provider, props = {}) => [provider, props];

const ProviderComposer = ({ providers, children }) => {
    for (let i = providers.length - 1; i >= 0; --i) {
        const [Provider, props] = providers[i];
        children = <Provider {...props}>{children}</Provider>
    }
    return children;
} 



const AppWrapper = () => {
    //const [me, setMe] = useState(null);

    const handleTest = () => {
        window.flutterApi.updateCurrentPosition('{Latitude: 59.3790054, Longitude: 17.8734656}');
    }

    return (
        <ThemeProvider theme={theme}>
        <Router>
            <ProviderComposer
            providers={[
                provider(StoreProvider),
                provider(UsersProvider),
                provider(ReportsProvider),
                provider(FilterProvider),
                provider(FlowViewProvider),
                provider(ProfileViewProvider),
                provider(MapProvider),
                provider(MedalViewProvider),
            ]}>
                    <App firebaseAnalytics={analytics}/>
            </ProviderComposer>    
            </Router>
            {/* <div style={{position:'absolute', top:'20px', left:'20px', zIndex:'10000', backgroundColor:'yellow'}} onClick={handleTest}>TEST</div> */}
        </ThemeProvider>    
    ); 
}

function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            // FB Production App
            window.FB.init({
                appId: 489840242496815,
                cookie: true,
                xfbml: true,
                version: 'v17.0'
            });
            // FB Test App
            /* window.FB.init({
                appId: 456678142624390,
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            }); */
            
        };

        resolve();

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
