import React, { createContext, useRef } from 'react';

const FlowViewContext = createContext();

export const FlowViewProvider = ({ children }) => {
    // Define refs here
    const selectedFlowReportRef = useRef(null);
    const flowReportsTokenRef = useRef(null);
    const flowViewPositionRef = useRef(0);
    const isRefreshingFlowViewRef = useRef(false);

    // Context value
    const flowViewStore = {
        selectedFlowReportRef, 
        flowReportsTokenRef, 
        flowViewPositionRef, 
        isRefreshingFlowViewRef
    };

    return (
        <FlowViewContext.Provider value={flowViewStore}>
            {children}
        </FlowViewContext.Provider>
    );
};

export default FlowViewContext;
