import React, {useState, useEffect, useContext} from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FilterContext from '../services/filterContext'
import ReportsContext from '../services/reportsContext'
import FlowViewContext from '../services/flowViewContext';
import StoreContext from '../services/storeContext'
import HelperService from '../services/helperService';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { parseISO, format } from 'date-fns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import sv from 'date-fns/locale/sv';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ClearIcon from '@mui/icons-material/Clear';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import '../styles/filterDialog.scss'

export default function FilterDialog({open, closeCB}) {
    const {t} = useTranslation('app');
    const ALL_ACTIVITIES = [{name: t('ice skating'), value: 'SKATING'}, {name: t('snowmobile'), value: 'SNOWMOBILE'}, 
                            {name: t('fishing'), value: 'FISHING'}, {name: t('hiking'), value: 'HIKING'}, 
                            {name: t('skiing'), value: 'SKIING'}, {name: t('ice sailing'), value: 'ICESAILING'}, 
                            {name: t('ice observation'), value: 'ICE_REPORT'}, 
                            {name: t('kayak'), value: 'KAYAK'}, {name: t('sup'), value: 'SUP'}];

    // just the name array of all activities
    const ALL_ACTIVITIES_NAMES = ALL_ACTIVITIES.map(a => a.value);                        

    let { mapReportsRef, flowReportsRef } = useContext(ReportsContext); 
    let { flowReportsTokenRef} = useContext(FlowViewContext);
    let {  devMode, setDevMode } = useContext(StoreContext); 
    let {  activityFilter, setActivityFilter, periodFilter, setPeriodFilter, setPreviousActivityFilter } = useContext(FilterContext);
    const [localActivityFilter, setLocalActivityFilter] = useState(['SKATING', 'SNOWMOBILE', 'FISHING', 'HIKING', 'SKIING', 'ICESAILING', 'ICE_REPORT', 'KAYAK', 'SUP']);
    const [localPeriodFilter, setLocalPeriodFilter] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [showFromPeriodInput, setShowFromPeriodInput] = useState(false);
    const [showToPeriodInput, setShowToPeriodInput] = useState(false);
    const [activityExpanded, setActivityExpanded] = useState(true);
    const [periodExpanded, setPeriodExpanded] = useState(true);
    const [commitReset, setCommitReset] = useState(false);
    const [devModeCounter, setDevModeCounter] = useState(0);
                            
    useEffect(() => {
        if (open && activityFilter) {
            setLocalActivityFilter(activityFilter.slice());
        }
       //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [open]); 

    useEffect(() => {
        if (commitReset) {
            setCommitReset(false);
            handleConfirm();
        }
        
       //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [commitReset]);

    useEffect(() => {
        if (activityFilter) setLocalActivityFilter(activityFilter.slice());
        //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [activityFilter]);

    useEffect(() => {
        if (periodFilter) {
            let localFilter = {};
            if (periodFilter.from) {
                localFilter.from  = periodFilter.from;
                setFromDate(periodFilter.from);
            }
            if (periodFilter.to) {
                localFilter.to = periodFilter.to;
                setToDate(periodFilter.to);
            }
            setLocalPeriodFilter(localFilter);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [periodFilter]);



    const handleActivityClick = (activity) => {
        let activityFilterCopy = localActivityFilter.slice();


        // if filter already has activity we remove it (unless if all activities are active)
        if (activityFilterCopy.includes(activity.value)) {
            // if all filters are selected and one item is clicked we do special treatment
            // and select only the clicked activity
            if (activityFilterCopy.length === ALL_ACTIVITIES_NAMES.length) {
                activityFilterCopy = [activity.value];
            }
            else {
                let index = activityFilterCopy.indexOf(activity.value);
                activityFilterCopy.splice(index, 1);
            }
        }
        else { // else we add it
            activityFilterCopy.push(activity.value);
        }
        setLocalActivityFilter(activityFilterCopy);
    }

    const handleConfirm = () => {
        /* console.log(localActivityFilter);
        console.log(localPeriodFilter); */
        mapReportsRef.current = [];
        flowReportsRef.current = [];
        if (flowReportsTokenRef) flowReportsTokenRef.current = null;

        if (activityFilter) { // needed to let the FlowView know it shall reload if it already is in view
            let activityFilterCopy = activityFilter.slice();
            setPreviousActivityFilter(activityFilterCopy);
        }
        
        if (localActivityFilter && localActivityFilter.length === ALL_ACTIVITIES.length) setActivityFilter(null);
        else setActivityFilter(localActivityFilter); 
        setPeriodFilter(localPeriodFilter); 
        closeCB();
    }

    const handleReset = (resetButtonFlow=false) => {
        setLocalActivityFilter([...ALL_ACTIVITIES_NAMES]);
        setFromDate(null);
        setToDate(null);
        setLocalPeriodFilter(null);
        if (resetButtonFlow) setCommitReset(true);
    }

    const filterIsReset = () => {
        return (localActivityFilter && localActivityFilter.length === ALL_ACTIVITIES.length && !fromDate && !toDate);
    }

    const filterHasChanged = () => {
         /* console.log(localActivityFilter);
        console.log('vs');
        console.log(activityFilter); */
 
        const allActivitiesAreSelected = !activityFilter && localActivityFilter.length === ALL_ACTIVITIES_NAMES.length;
        

        if (!allActivitiesAreSelected) {
            if (HelperService.areStringArraysDifferent(localActivityFilter, activityFilter)) return true;
        } 
        if (localPeriodFilter && !periodFilter) return true;
        if (!localPeriodFilter && periodFilter) return true;
        if (localPeriodFilter && periodFilter && localPeriodFilter.from !== periodFilter.from) return true;
        if (localPeriodFilter && periodFilter && localPeriodFilter.to !== periodFilter.to) return true;
        return false;
    }

    const formatDate = (date) => {
        let dt = parseISO(date);
        return format(dt, 'dd/M yyyy');
        }

    /* const isActivityCheckboxesIndeterminate = () => {
        let values = Object.values(activityCheckboxes);
        let hasFalse = values.includes(false); 
        let hasTrue = values.includes(true);
        if (hasFalse && hasTrue) return true;
        return false; 
    }

    const isAllActivityCheckboxesChecked = () => {
        let values = Object.values(activityCheckboxes);
        let hasFalse = values.includes(false); 
        return !hasFalse;
    } */

    

    

    const handleDateChange = (e, from) => {
        if (from) {
            let fromIso = null;
            if (e) fromIso = e.toISOString();
            setFromDate(fromIso);
            setShowFromPeriodInput(false);
            let localPeriod = {from: fromIso}
            if (localPeriodFilter && localPeriodFilter.to) localPeriod.to = localPeriodFilter.to;
            setLocalPeriodFilter(localPeriod);
        }
        else {
            setToDate(e);
            let toIso = null;
            if (e) toIso = e.toISOString();
            setToDate(toIso);
            let localPeriod = {to: toIso}
            if (localPeriodFilter && localPeriodFilter.from) localPeriod.from = localPeriodFilter.from;
            setLocalPeriodFilter(localPeriod);
            setShowToPeriodInput(false);
        }
        
       // togglePeriodInput('from');
    }

    const handleClose = () => {
        handleReset();
        closeCB();
    }
    
    const handleClickAway = () => {
        setShowFromPeriodInput(false);
        setShowToPeriodInput(false);
    }

    // 4 consecutives click on the 'Rapportfilter' to enter developer mode.
    const handleDevModeClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (devModeCounter >= 3) {
            console.log('Toggling dev mode');
            setDevMode(!devMode);
            setDevModeCounter(0);
        }
        else setDevModeCounter(devModeCounter + 1);
    }

    const clearDate = (dateToClear) => {
        if (dateToClear === 'from'){
            setFromDate(null);
            setShowFromPeriodInput(false);
        }
        else if (dateToClear === 'to'){
            setToDate(null);
            setShowToPeriodInput(false);
        }
    }


    

    const renderActivityList = () => {
        return (
            <div className="cb-list">
                
                {ALL_ACTIVITIES.map((activity, index) => (
                    <div key={index} onClick={(e)=>handleActivityClick(activity)} 
                        className={localActivityFilter && localActivityFilter.includes(activity.value) ? `activity-item-2 ${activity.value} selected` : `activity-item-2 ${activity.value} unselected`}>
                        {HelperService.getActivityIcon(activity.value)}
                        <span className='activity-label'>{activity.name}</span>
                    </div>                    
                ))}

            </div>
        );
    }


    const renderPeriodPicker = () => {
        return (
            <div className="period-text">
                <div className="subtitle">
                {t('shows reports between')} 
                </div>
                <div className="period-text-bot">
                    {
                        showFromPeriodInput ? 
                        <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="period-pick">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
                            <MobileDatePicker
                                label={t('from')}
                                inputFormat="dd/MM/yyyy"
                                value={fromDate ? new Date(fromDate) : null}
                                onChange={(e)=>handleDateChange(e, true)}
                                onClose= {()=>{setShowFromPeriodInput(false)}}
                                renderInput={(params) => <TextField variant="standard" {...params} />}
                                />
                            </LocalizationProvider>
                            <ClearIcon onClick={()=>{clearDate('from')}}/>
                        </div>
                        </ClickAwayListener>
                        :
                        <div className="clickable-date" onClick={()=>setShowFromPeriodInput(true)}>
                            {fromDate ? <span>{formatDate(fromDate)}</span> : <span className='day-label'>{t('early age')}</span>}
                        </div>
                    } 
                <span className="middle"><ArrowBackIcon/><div className='line'><div/><div className='hline'/><div/></div><ArrowForwardIcon/></span>
                    {
                        showToPeriodInput ? 
                        <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="period-pick">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
                            <MobileDatePicker
                                label={t('to')}
                                inputFormat="dd/MM/yyyy"
                                value={toDate ? new Date(toDate) : null}
                                onChange={(e)=>handleDateChange(e, false)}
                                onClose= {()=>{setShowToPeriodInput(false)}}
                                renderInput={(params) => <TextField variant="standard" {...params} />}
                                />
                            </LocalizationProvider>
                            <ClearIcon onClick={()=>{clearDate('to')}}/>
                        </div>
                        </ClickAwayListener>
                        :
                        <div className="clickable-date" onClick={()=>setShowToPeriodInput(true)}>
                            {toDate ? <span>{formatDate(toDate)}</span> : <span className='day-label'>{t('today')}</span>}
                        </div>
                    } 
                </div>    
                
            </div>
        );
    }

    /* console.log('***** activityFilter *******');
    console.log(activityFilter); */
    

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="Filterdialog" aria-describedby="abc" className="filter-dialog">
        <div className="content-root">
            <div className="filter-header">
                <span onClick={(e)=>handleDevModeClick(e)}>{t('report filter')}</span>
                <div className="filter-header-btns">
                    { filterHasChanged() ? <span className='header-btn confirm' onClick={handleConfirm}>{t('save filter')}</span> : null }
                    { !filterIsReset() ? <span className='header-btn' onClick={()=>handleReset(true)}>{t('clear')}</span> : null }
                </div>
                <CloseIcon className="close-btn" onClick={handleClose}/>
            </div>

            <div className="filter-body">
            <div className="content">
                
                <div className="header-item" onClick={()=> setActivityExpanded(!activityExpanded)}>
                    <div>{t('activity')}</div>
                    { activityExpanded ? <ExpandMoreIcon className="show-button" /> : <ExpandLessIcon className="show-button" style={{transform: 'rotate(90deg)'}}/> }
                </div>
                

                
                { activityExpanded ? renderActivityList() : null}

                <div className="header-item" onClick={()=> setPeriodExpanded(!periodExpanded)}>
                    <span>{t('period')}</span>
                    { periodExpanded ? <ExpandMoreIcon className="show-button"/> : <ExpandLessIcon className="show-button" style={{transform: 'rotate(90deg)'}}/> }
                </div>
                { periodExpanded ? renderPeriodPicker() : null}

                <div className="button-row">
                    <Button className="filter-confirm-button" color="primary" fullWidth disabled={!filterHasChanged()} variant="contained" onClick={handleConfirm} disableElevation> {t('save filter')} </Button>
                    <Button className="filter-cancel-button" color="primary" disabled={filterIsReset()} variant="outlined" onClick={handleReset} disableElevation> {t('clear')} </Button>
                </div>
            </div>
            </div>

            
        </div>
      </Modal>
  );
}