import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HelperService from '../services/helperService';
import MedalViewContext from '../services/medalViewContext';
import UsersContext from '../services/usersContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { ReactComponent as MedalIcon } from '../resources/activityicons/medalj.svg';
import HelpIcon from '@mui/icons-material/Help';
import ApiService from '../services/apiService';
import '../styles/medalView.scss'
import '../styles/medalIcon.scss'
import StoreContext from '../services/storeContext';
import { InfoModal } from '../services/modalService';

/* import InfiniteScroll from "react-infinite-scroll-component";
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
 */

function MedalView() {
    const { t, i18n } = useTranslation('app');
    const MEDAL_HELP_TEXT = [t('medal help text 1'), t('medal help text 2'), t('medal help text 3')];
    const BATCH_SIZE = 20;
    let { topReportersTokenRef, topReporters, setTopReporters, showPromo, setShowPromo} = useContext(MedalViewContext); 
    let { setShowUserProfile} = useContext(UsersContext); 
    let {  setInfoModal, open } = useContext(StoreContext);
    const [activeMedalTab, setActiveMedalTab] = useState(0);

    useEffect(() => {
        if (topReporters.length === 0 ) {
            fetchTopReporters();
        }
        
    //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []); 

    const fetchTopReporters = async () => {
        if (topReportersTokenRef.current === 'END_TOKEN') return;
        let result = null;
        try {
            //if (topReportersRef.current && topReportersRef.current.length === 0) setIsFetchingFlowViewData(true);
            const tokenToUse = topReportersTokenRef.current;
            topReportersTokenRef.current = 'END_TOKEN';
            //await HelperService.sleep(100); 
            result = await ApiService.callApi(ApiService.getTopReporters, [BATCH_SIZE, tokenToUse], 'getTopReporters');
        } catch (error) {
            console.log(error);
        }

        if (result && result.items) {

            //topReportersRef.current = topReportersRef.current.concat(result.items);
            setTopReporters(topReporters.concat(result.items));

            if (result.nextPageToken) topReportersTokenRef.current = result.nextPageToken;
            else topReportersTokenRef.current = 'END_TOKEN';

            //setReportCount(topReportersRef.current.length);
        }
    }



    const handleTabChange = (event, newValue) => {
        setActiveMedalTab(newValue);
      };

      const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
                <div>
              {children}
              </div>
            )}
          </div>
        );
      }  

      const handlePromoClose = () => {
        setShowPromo(false);
      }

      const renderProfileImage = (fileName) => {
        return (
            <div className='profile-img'>  
                
                { fileName ? 
                    <img src={ApiService.PROFILE_BUCKET + fileName} alt="profile"></img>
                    :
                    <AccountCircleIcon />
                }
            </div>
            
        )
    } 

      const renderMedalists = () => {
        let medalists = [];
        // vitt, rött, orange, gult, grönt, blått, brunt, svart
        let currentLevel = null;
        let unrankedCount = 0;
        for (const m of topReporters) {
            const level = HelperService.getMedalLevel(m.numOfReports);
            if (level === 0) unrankedCount++;
            if (unrankedCount === 4) {
                medalists.push(
                    <div className='more-reporters' key='many-more'>
                        {t('and many more')}...
                        </div>
                );
                break;
            }
            if (level !== currentLevel) {
                // Add 'Röd nivå' header
                currentLevel = level;
                if (level > 0) {
                    medalists.push(
                        <div className='medal-header' key={`header-level-${level}`}>
                            <div className={`medal-icon level-${level}`}>
                                <MedalIcon />
                            </div>
                            <span className='header-text'>{HelperService.formatMedalLevel(level, i18n.language)} {t('level')}
                                <span className='header-subtext'>({HelperService.getReportCountForMedalLevel(level)}+ {t('reports')})</span>
                            </span> 
                        </div>
                    );
                }
                else {
                    medalists.push(
                        <div className='medal-header' key={`header-level-unranked`}>
                            <span className='header-text'>{t('unranked')}
                            </span> 
                        </div>
                    );
                }
                    
            }
            medalists.push(
                <div className='medalist' onClick={()=>{setShowUserProfile(m.id)}} key={`medalist-key-${m.id}`}>
                    {renderProfileImage(m.profileImage)}
                    <div className='name'>
                        {m.displayName}
                    </div>
                    <div className='num-reports'>
                        <span>{m.numOfReports}</span> <span className='report-label'>{t('reports')}</span>
                    </div>
                </div>
            );
        }
        return (
            <div className='medalist-list'>
                {medalists}
            </div>
        );
      }

    /*   const RefreshItem = ({pullIcon}) => {
        return (
            <>
                <div className="refresh-button-row">
                    <div className="rounded-btn">
                    { pullIcon ? <ArrowUpwardIcon/> : <ReplayIcon/> }
                    { pullIcon ? <span>{t('pull for new')}</span>  : <span>{t('release for new')}</span>  }
                    </div>
                </div>
            </>
            
        );
    }
    let isRefreshing = false;
    const spinnerClass = `refresh-spinner ${isRefreshing ? 'show' : ''} ${!isRefreshing ? 'animate-out' : ''}`;

    const listEnd = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }} className="loading-text"><span>{t('last report add one more')}</span> <FavoriteIcon style={{ marginLeft: '5px', fill: '#E89D42' }} /></div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }} className="loading-text" onClick={()=>fetchTopReporters()}>
                    <div style={{ display: 'inline-flex', justifyContent: 'center', border:'1px solid #999999', padding:'13px', borderRadius:'30px', cursor:'pointer'}}>
                        <span>{t('reload')}</span> <ReplayIcon style={{ marginLeft: '5px', fill: '#999999' }}/>
                    </div>
                </div>
            </>
            
        );
    } */
  
 return (
    <div className="medal-view">
        <Tabs value={activeMedalTab} onChange={handleTabChange} aria-label="basic tabs example"  variant="scrollable" scrollButtons allowScrollButtonsMobile>
          <Tab label={t('medal league')} id="profile-data" aria-labelledby="profile-data" />
          {/* <Tab label={t('municipality league')} id="profile-data" aria-labelledby="bio-data" />  */}
        </Tabs>

        <div className="help-icon">
            <HelpIcon onClick={()=>setInfoModal({content: MEDAL_HELP_TEXT, okCB: null, cancelCB: null})}/>
        </div>
        

        <TabPanel value={activeMedalTab} index={0}>
            {renderMedalists()}
        </TabPanel>

        {/* <TabPanel value={activeMedalTab} index={1}>
            {
                <InfiniteScroll  className="inf-scroll" dataLength={topReporters.length} 
                next={fetchTopReporters} hasMore={false} loader={<div >ladd</div>}
                height={400} onScroll={()=>{}} endMessage={listEnd()}
                refreshFunction={fetchTopReporters}
                pullDownToRefresh pullDownToRefreshThreshold={100}
                pullDownToRefreshContent={
                    <RefreshItem pullIcon/>
                }
                releaseToRefreshContent={
                    <RefreshItem /> 
                }
            >
                <div className={spinnerClass}>
                    spin
                </div> 
                
                {renderMedalists()}
            </InfiniteScroll>
            }
        </TabPanel>   */}

        {
            !showPromo ? null :
            <InfoModal header={t('medal promo header')} sidebarOpen={open} infoModalOkCB={handlePromoClose}
            text=
            {
                [t('medal promo 1'), 
                t('medal promo 2'),
                t('medal promo 3'),
                t('medal promo 4')]
            } 
            />
        }
    </div>


  );

  

}

export default MedalView;