import { Route, Routes } from "react-router-dom";
import React, { lazy } from 'react';
import MedalView from "./views/medalView";

const ReportView = lazy(() => import('./views/singleReportView'));
const FlowView = lazy(() => import('./views/flowView'));
const NewView = lazy(() => import('./views/newView'));
const UserView = lazy(() => import('./views/userView'));
const ForgotPwView = lazy(() => import('./views/forgotPwView'));
const FaqView = lazy(() => import('./views/faqView'));
const AboutView = lazy(() => import('./views/aboutView'));
const HomeView = lazy(() => import('./views/homeView'));
const ProfileView = lazy(() => import('./views/profileView'));
const AllReportsView = lazy(() => import('./views/allReportsView'));
const AllUsersView = lazy(() => import('./views/allUsersView'));
const MapView = lazy(() => import('./views/mapView'));

/* const RoleComponent = (props) => {
    let currentRole = props.me ? props.me.role : 'public';
    let baseRole = props.role ? props.role : 'public';
    if (roleMatchesOrIsStronger(currentRole, baseRole)) {
        return (
            <>
                {props.children}
            </>
        )
    }
    return null;
}; */

const RoleComponent = ({ me, role, children }) => {
    let currentRole = me ? me.role : 'public';
    let baseRole = role ? role : 'public';
    if (roleMatchesOrIsStronger(currentRole, baseRole)) {
        return children;
    }
    return null;
};

const roleMatchesOrIsStronger = (roleToMatch, baseRole) => {
    const allRoles = ['superadmin', 'admin', 'user', 'public'];
    if (!allRoles.includes(roleToMatch)) return false;
    const roleToMatchPosition = allRoles.indexOf(roleToMatch);
    const baseRolePosition = allRoles.indexOf(baseRole);
    return roleToMatchPosition <= baseRolePosition;
};

function RouteSwitch({me, scrollBoxRef, redrawAppTrigger, singleReportClose, setAppViewMode, isFetchingFlowViewData, 
    setIsFetchingFlowViewData, reportCreated, setReportCreated}) {
    
    return (
        <Routes>
            <Route path="/about" element={<RoleComponent me={me} role='public'><AboutView /> </RoleComponent>} />
            <Route path="/faq" element={<RoleComponent me={me} role='public'><FaqView /></RoleComponent>} />
            <Route path="/medals" element={<RoleComponent me={me} role='public'><MedalView /></RoleComponent>} />
            <Route path="/map" element={<MapView />} />
            <Route path="/profile" element={<RoleComponent me={me} role='user'><ProfileView scrollBoxRef={scrollBoxRef} /></RoleComponent>} />
            <Route path="/add_report" element={<RoleComponent me={me} role='user'><NewView reportCreated={reportCreated} setReportCreated={setReportCreated}/></RoleComponent>} />
            <Route path="/report/:reportId" element={<RoleComponent me={me} role='public'><ReportView setAppViewMode={setAppViewMode} closeViewCB={singleReportClose} /></RoleComponent>} />
            <Route path="/user/:userId" element={<RoleComponent me={me} role='public'><UserView /></RoleComponent>} />
            <Route path="/users" element={<RoleComponent me={me} role='admin'><AllUsersView /></RoleComponent>} />
            <Route path="/allreports" element={<RoleComponent me={me} role='admin'><AllReportsView /></RoleComponent>} />
            <Route path="/dashboard" element={<RoleComponent me={me} role='admin'><HomeView /></RoleComponent>} />
            <Route path="/forgotpw" element={<RoleComponent me={me} role='public'><ForgotPwView /></RoleComponent>} />
            <Route path="/" element={<RoleComponent me={me} role='public'><FlowView isFetchingFlowViewData={isFetchingFlowViewData} setIsFetchingFlowViewData={setIsFetchingFlowViewData} /></RoleComponent>} />
        </Routes>
    );
} 
    
    export default RouteSwitch;

