import React, {useEffect} from 'react';
import '../styles/suspenseFallback.scss'

export default function SuspenseFallback() {
    useEffect(() => {
        
        // in react18 this is not called after first render so we do the same
        // displey=none setting also in App.js
        return () => { 
            const loader = document.getElementById('preLoadIndicator');
            if (loader) {
                loader.style.display = 'none';
            }
         };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const renderFullscreenSuspense = () => {
        return (
            <div className="suspense" style={{}}>
            <div id="preLoadIndicator" style={{ backgroundColor: "#fff" }}>
                <div className="header">
                    <div className="bars">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    
                    <div className="filter">&#x26CA;</div>
                </div>

                <div className="bottom">
                    <div className="sidebar">
                        <div className="h">
                            O &nbsp;utekoll
                        </div>
                        <p>HEM</p>
                        <p>FLÖDE</p>
                        <p>KARTA</p>
                        <p>VANLIGA FRÅGOR</p>
                    </div>
                    <div className="mainarea">
                        <div className="boxes">
                            <div className="reportbox"></div>
                            <div className="reportbox"></div>
                            <div className="reportbox"></div>
                            <div className="reportbox"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        );
    }
 

    return (
        <> {
            renderFullscreenSuspense() 
        }
        </>
        
    );
}