import React, {useState, useEffect} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApiService from '../services/apiService';
import '../styles/profileImage.scss'
 

/* Display the profile image for the user with 'userId' or a default face icon if the user hasn't provided a profile image.
    E.g. <ProfileImage userid='xyz123' />
*/
function ProfileImage({userId}) {
    const [showUserIcon, setShowUserIcon] = useState(false);

    useEffect(() => {
        setShowUserIcon(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userId]);

    const handleNoProfileImage = () => {
        setShowUserIcon(true);
    }
  
    return (
        
        <div className="profile-image">
        <AccountCircleIcon />
                { showUserIcon ? null :
                    <img onError={handleNoProfileImage} src={ApiService.PROFILE_BUCKET + userId + '.jpg'} alt="profile"></img>
                }
        </div>

    );

}
//

export default ProfileImage;