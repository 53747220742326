import React, { createContext, useRef, useState } from 'react';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
    // Define refs and state here
    const profileUsersRef = useRef([]);
    const allUsersTokenRef = useRef(null);
    const [usersViewPage, setUsersViewPage] = useState(0);
    const [userOrderBy, setUserOrderBy] = useState('createdOn');
    const [userOrder, setUserOrder] = useState('desc');
    const [userFilter, setUserFilter] = useState(null);
    const [showUserProfile, setShowUserProfile] = useState(null);
    const [allUsers, setAllUsers] = useState([]);

    const usersStore = {
        allUsersTokenRef,
        profileUsersRef,
        usersViewPage, setUsersViewPage,
        userOrderBy, setUserOrderBy,
        userOrder, setUserOrder,
        userFilter, setUserFilter,
        showUserProfile, setShowUserProfile,
        allUsers, setAllUsers
    };

    return (
        <UsersContext.Provider value={usersStore}>
            {children}
        </UsersContext.Provider>
    );
};

export default UsersContext;
