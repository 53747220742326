import React from 'react';
import PulsatingLogo from './pulsatingLogo';
import ReplayIcon from '@mui/icons-material/Replay';
import '../styles/loadingOverlay.scss'

export default function LoadingOverlay({text, opaque}) {
  return (
    <div className="loading-overlay" style={opaque ? {opacity:1, backgroundColor:'white'} : null}>
        <PulsatingLogo />
        <div className="loading-text">
            {text ? text: ' '}
        </div>
    </div>
  );
}

export function LoadingComponent({loadingText, buttonText, reloadCB})  {
    return (
        <>
        <div className="loading-component">
        <div className="intermediate-loads">
            <PulsatingLogo />
            <div className="loading-text">
                {loadingText}
            </div>
        </div> 
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }} className="reload-btn" onClick={reloadCB}>
                <div style={{ display: 'inline-flex', justifyContent: 'center', border:'1px solid #999999', padding:'13px', borderRadius:'30px', cursor:'pointer'}}>
                    <span>{buttonText}</span> <ReplayIcon style={{ marginLeft: '5px', fill: '#999999' }}/>
                </div>
        </div>
        </div> 
        </>
    );
}