import React, {useEffect, useState, useContext} from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ApiService from '../services/apiService';
import UsersContext from '../services/usersContext';
import FilterContext from '../services/filterContext';
import PulsatingLogo from '../components/pulsatingLogo';
import HelperService from '../services/helperService';
import MedalViewContext from '../services/medalViewContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import allCounties from '../resources/counties.json';
import { ReactComponent as MedalIcon } from '../resources/activityicons/medalj.svg';
import '../styles/medalIcon.scss'
import '../styles/userView.scss'
import '../styles/userBio.scss'
import '../styles/reportSlide.scss'
import '../styles/countyPlate.scss'


/* 
*/
function UserView({userRef, handleUserProfileClose}) {
    const { t, i18n } = useTranslation('app');
    let { userId } = useParams();
    let navigate = useNavigate();
    const location = useLocation();
    let counties = allCounties.SE;
    //const [fetchingUser, setFetchingUser] = useState(false);
    const [user, setUser] = useState(null);
   // const [viewWidth, setViewWidth] = useState('300px');
    const [showError, setShowError] = useState(false);
    let { topReporters} = useContext(MedalViewContext);
    let {  setQuickFilter } = useContext(FilterContext);
    let {  profileUsersRef } = useContext(UsersContext);
    

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        fetchUser();


        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    useEffect(() => {
        if (user) handleResize();
        //positionIt();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [user]);
    
    const fetchUser = async () => {
        let id = userRef ? userRef : userId; 
        if (topReporters) {
            let topReporter = topReporters.find(tr => tr.id === id);
            if (topReporter) {
                setUser(topReporter);
                return;
            }
        }
        let result = null;
        try {
            //setFetchingUser(true);
            result = profileUsersRef.current.find(pu => pu.id === id);
            if (!result) {
                result = await ApiService.callApi(ApiService.getUser, [id], 'getUser');
                profileUsersRef.current.push(result);  
            } 
            //setFetchingUser(false);
        } catch (error) {
            console.log(error);
            setShowError(true);
        }
        if (result) setUser(result); 
    }

    const handleResize = () => {
        let c = document.getElementById('centerItem');
        if (c) {
          //  setViewWidth(c.offsetHeight / 1.25 + 'px');
        }
        
    }


    const showReports = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setQuickFilter({propName: 'reporterRef', propVal: user.id, displayVal: user.displayName});
        // if we are in the medal view we need to move to the flowview
        if (location.pathname.includes('medals')) navigate('/');
        handleUserProfileClose();
    }

    const renderBioText = () => {
        if (!user.bioText) return (
            <div className="bio-text no-bio-text">{t('this user doesnt have a bio text')}</div>
        );
        let bio = HelperService.sanitizeRichText(user.bioText);
        return <div className='bio-text' dangerouslySetInnerHTML={{ __html: bio }} />
    }


    /* const positionIt = () => {
        let container = document.getElementById('plate-container');
        let elem = document.getElementById('user-county');
        if (!elem || !container) return;
        let rect = elem.getBoundingClientRect();
        let leftOffset = rect.width/4;
        elem.style.left = leftOffset + 'px';
    } */

    const renderUserLocation = () => {
        let county = null;
        if (user.homeCounty) {
            county = counties.find(c => c.countyIdentifier === user.homeCounty);
        }
        if (county) {
            return (
                <div className='bg-plate'>
                                <div className='prop-group'>
                                    <div className='title'>
                                        {t('from')}
                                    </div>
                    <div className='location-item'>
                        <div className='plate-container'>
                            <div id='user-county' className={'county-plate _' + county.countyCode}></div>
                        </div>
                        <div className='level-text'>{county.name}</div>
                    </div>
                    </div>
                    </div>
            );
        }
        return null;
        
    }

    const renderMedalLevel = () => {
        if (!user.numOfReports || user.numOfReports < 3) return null;
        const level = HelperService.getMedalLevel(user.numOfReports);
        if (level) {
            return (
                <div className='bg-plate'>
                    <div className='prop-group'>
                        <div className='title'>
                            {'Medaljnivå'}
                        </div>
                        <div className={`medal-icon level-${level}`}>
                            <MedalIcon />
                        </div>
                        <span className='level-text'>{HelperService.formatMedalLevel(level, i18n.language)}</span>
                    </div>
                </div>
            );
        }
        return null;
        
    }

    const renderUser = () => {
        if (!user) return null;
        return (
            
                <div className="single-user-container user-bio">
                    
                    <div className="name-stats-section">
                        <div className="name-section">
                            { user.profileImage ? 
                                    <img className="profile-image" src={ApiService.PROFILE_BUCKET + user.profileImage} alt="profile"></img> :
                                    <AccountCircleIcon />
                            }
                            <div className="name-dispname">
                                <div className="dispname">
                                    {user.displayName}
                                </div>
                                {
                                    user.enablePublicProfile ?
                                    <div className="name">
                                        {user.name}
                                    </div>
                                    : null
                                }
                                
                            </div>
                        </div>

                        <div className="stats-section report-properties">
                            <div className='bg-plate pointer-cursor' onClick={showReports}>
                                <div className='prop-group'>
                                    <div className='title'>
                                        {t('reports')}
                                    </div>
                                    <div className={`circled-num`}>{user.numOfReports}</div>
                                    <span className='level-text'>{t('show')}</span>
                                </div>
                            </div>

                            {renderUserLocation()}

                            {renderMedalLevel()}

                        </div>
                    </div>

                    

                    

                    { user.enablePublicProfile ? renderBioText() : 
                        <div className='no-public-profile'>{user.displayName} {t('doesnt have a public profile')}</div>
                    }
                    
                </div>
            
        );
    }


    //console.log(user);

    return (
        <div className="user-view"> 
        { user ?  
            renderUser() : 
                <div className='loading-user'>
                {
                showError ?  
                    <div className='no-public-profile'>{t('unfortunately we couldnt find that user')}</div>
                :
                    <>
                    <PulsatingLogo />
                    <div className="loading-text">
                        {t('getting user')}
                    </div>
                    </>
                }
                    
                </div>
        }
        
        </div>
    );
  

}

export function ModalUserView({userRef, showUserProfile, handleUserProfileClose, sidebarOpen}) {
    return (
        <Modal open={showUserProfile ? true : false} onClose={handleUserProfileClose} 
            aria-labelledby="User Profile" className={sidebarOpen ? 'user-profile-modal sidebar-open' : 'user-profile-modal'}>
                <div className="content-root">
                <Button className="close-btn" color="primary" onClick={handleUserProfileClose}><CloseIcon/></Button>
                    <UserView userRef={userRef} handleUserProfileClose={handleUserProfileClose}/>
                </div>
        </Modal>
    )
}

export default UserView;


