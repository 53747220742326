import ApiService from './apiService'
import { logEvent } from "firebase/analytics";
import { GoogleAuthProvider, FacebookAuthProvider, getAdditionalUserInfo, deleteUser } from "firebase/auth";

const AuthenticationService = {};

AuthenticationService.formatLengthSpan = function (span) {
    if (!span) return null;
    if (span[0] === span[1]) {
        return span[0]; 
    }
    return span[0] + '-' + span[1];
    //return span[0] + ' cm - ' + span[1];
};

AuthenticationService.handleAuthStateChange = function (authenticationCompletedCB, sendFlutterNotification) {
    return async (user) => {
        //console.log('APP AUTH STATE CHANGED: ', user);
       // if (getViewMode() === 'REGISTRATION') return;
       if ( window && window.localStorage && 
            window.localStorage.getItem('redirectFrom') === 'registration') {
                //console.log('still in registration flow, just returning');
                //console.log('user', user);
                if (!user || !user.emailVerified) return;
        }
        
        //console.log(viewMode);
        //await HelperService.sleep(10);
        
        if (user) {
            //if (user.providerData) console.log(user.providerData[0]);
            const token = await user.getIdToken();
            window.localStorage.setItem('tempToken', token);
            ApiService.accessToken = token;
            await authenticationCompletedCB();
        }
        else {
            window.localStorage.removeItem('tempToken');
            await authenticationCompletedCB();
        }
    };
};


/* called when we are getting redirect from Google or Facebook login/registration via signInWithRedirect. 
   Since signInWithRedirect stopped working in Dec 2022 (result==null at login from mobile device, some Firebase issue?) 
   and we now use signInWithPopup instead, this method is not used.
*/
AuthenticationService.handleRedirectResult = async function (setViewMode, setMe, setInfoModal, setOnboardingErrorCode, location, analytics, result, fcmToken, setFcmToken) {
    //console.log('IN: Handle redirect result');  
    if (!result) return;

      let providerId = result.providerId; // 'facebook.com', 'google.com', 
      let operationType = result.operationType; // 'signIn'

      if (operationType === 'signIn' && location.pathname === '/comeonboard') {
          console.log('ALERT *** Account already existed, logging in instead');
      }

      let additionalUserInfo = getAdditionalUserInfo(result);
      //console.log('additionalUserInfo', additionalUserInfo);
      if (additionalUserInfo && additionalUserInfo.isNewUser) {
          //1. check if user already exists somehow
          let userMe = await ApiService.callApi(ApiService.getMe, [{fcmToken: fcmToken}], 'getMe');
          if (!userMe) {
              //2. Create the user in DB
              let displayName = window.sessionStorage.getItem('displayName');
              if (displayName) {
                  let userData = { email: additionalUserInfo.profile.email, displayName: displayName, emailVerified: true };
                  if (additionalUserInfo.profile.name)
                      userData.name = additionalUserInfo.profile.name;
                  if (providerId === 'facebook.com') userData.provider = 'facebook.com';
                  if (providerId === 'google.com') userData.provider = 'google.com';
                  
                  let user = await ApiService.callApi(ApiService.registerUser, [result.user.uid, userData], 'registerUser');
                  window.sessionStorage.removeItem('displayName');

                  //3. set user as logged in
                  let credential = null;
                  if (providerId === 'google.com') {
                      credential = GoogleAuthProvider.credentialFromResult(result);
                      logEvent(analytics, 'sign_up', {method: "Google"});
                  }
                  else if (providerId === 'facebook.com') {
                      credential = FacebookAuthProvider.credentialFromResult(result);
                      logEvent(analytics, 'sign_up', {method: "Facebook"});
                  }
                  ApiService.accessToken = credential.accessToken;
                  if (user.fcmToken && user.fcmToken !== fcmToken) setFcmToken(user.fcmToken);
                  setMe(user);
              }
              else { //no displayName means this was a Google/FB login without registration
                  //console.log('Login without registration - REGISTER FIRST!!');
                  deleteUser(result.user).then(() => {
                      console.log(`Unregistered ${providerId} user detected`);
                  }).catch((error) => {
                      console.log(error);
                  });
                  let accountType = 'Googlekonto';
                  if (providerId === 'facebook.com') accountType = 'Facebook-konto';
                //console.log('accountType', accountType);
                  setInfoModal({content: [`Det finns ingen Utekollanvändare kopplat till ditt ${accountType}. Börja med att registrera dig innan du loggar in.`]});
              }
          }
      }

      
      window.localStorage.removeItem('redirectFrom');
      setOnboardingErrorCode(null);
      window.localStorage.removeItem('redirectFrom');
      //console.log('setting viewmode to APP 3!!');
      setViewMode('APP');

      // if redirect came from login or registration we go home 
     /* if (location.pathname === '/comeinside' || location.pathname === '/comeonboard') {
          history.push('/');
      }*/

  };


export default AuthenticationService;

