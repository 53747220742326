import React, { createContext, useRef, useState } from 'react';

const ReportsContext = createContext();

export const ReportsProvider = ({ children }) => {
    // Define states and refs here
    const [reportOrderBy, setReportOrderBy] = useState('createdOn');
    const [reportOrder, setReportOrder] = useState('desc');
    const allReportsTokenRef = useRef(null);
    const [reportsViewPage, setReportsViewPage] = useState(0);
    const [allReports, setAllReports] = useState([]);
    const [selectedReportsInTable, setSelectedReportsInTable] = useState([]);
    const [reportsTableDrawerState, setReportsTableDrawerState] = useState('DRAWER_CLOSED');
    const mapReportsRef = useRef([]);
    const flowReportsRef = useRef([]);
    const myReportsRef = useRef([]); 
    const [selectedReport, setSelectedReport] = useState(null);
    const [pageCursor, setPageCursor] = useState(null);
    const [tablePage, setTablePage] = useState(0);

    const reportsStore = { reportsViewPage, setReportsViewPage,
        allReportsTokenRef, reportOrderBy, setReportOrderBy, reportOrder, setReportOrder,
        allReports, setAllReports, selectedReportsInTable, setSelectedReportsInTable,
        reportsTableDrawerState, setReportsTableDrawerState, mapReportsRef, 
        flowReportsRef, myReportsRef, selectedReport, setSelectedReport, pageCursor, setPageCursor, 
        tablePage, setTablePage
    };

    return (
        <ReportsContext.Provider value={reportsStore}>
            {children}
        </ReportsContext.Provider>
    );
};

export default ReportsContext;
