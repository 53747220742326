import React, {useContext, useState} from 'react';
import {useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import StoreContext from '../services/storeContext'
import FilterContext from '../services/filterContext'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterDialog from './filterDialog'
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import ProfileImage from './profileImage';
import { ReactComponent as UtekollText } from '../resources/logos/textlogoUk.svg'



import '../styles/topBar.scss'
import '../styles/countyPlate.scss'
import '../styles/municipalityPlate.scss'

function TopBar({open, toggleDrawerCB, onboardingCB}) {
    const {t} = useTranslation('app');
    let { me, devMode, isFlutterApp } = useContext(StoreContext);
    let {  activityFilter, periodFilter, quickFilter, setQuickFilter } = useContext(FilterContext);
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const [showFilterPanel, setShowFilterPanel] = useState(false);

    const toggleFilter = () => {
        setShowFilterPanel(!showFilterPanel);
    };

    // to ensure text doesn't get too long in the top-bar we reestrict it to 15 chars
    const shortenTextIfNeeded = (text) => {
        if (text && text.length > 16) {
            return text.substring(0, 12) + '...';
        }
        return text;
    };

    const renderLogo = () => {
        // if side menu is open we show no logo (since it is in top of side menu too)
        if (open) return null; 
        // if mobile screen and we show the quick filter label we have no room for the logo
        if (isMobile && quickFilter) return null;
        return (
            <>{
                isFlutterApp ? 
                <div className="utekolltext-white"><UtekollText/></div> :
                <div className="logo-white"></div>
            }</>
        
        );
    }

    const renderQuickFilterIcon = () => {
        if (quickFilter.propName === 'countyName') {
            return (
                <div className="quick-filter-icon">
                    <div className={'county-plate _' + quickFilter.iconVal}></div>
                </div>
            )
        }
        if (quickFilter.propName === 'municipalityName') {
            return (
                <div className="quick-filter-icon">
                    <div className={'municipality-plate _' + quickFilter.iconVal}></div>
                </div>
            )
        }
        if (quickFilter.propName === 'reporterRef') {
            return (
                <div className="quick-filter-icon">
                    <ProfileImage userId={quickFilter.propVal}/>
                </div>
            )
        }
        return null;
    }
    
        const renderQuickFilter = () => {
        return (
        <div className='quick-filter-label'>
                {renderQuickFilterIcon()}
                    <div className='quick-filter-content'>
                        <div className='title'>{t('reports from')}</div>
                        <div className='filter-prop'>{quickFilter.displayVal ? shortenTextIfNeeded(quickFilter.displayVal) : shortenTextIfNeeded(quickFilter.propVal)}</div>
                        
                    </div>
                    <Button className="close-btn" color="primary" onClick={()=>{setQuickFilter(null)}}><CloseIcon/></Button>
                </div>
        );
    }


return (
    <AppBar id="topBar" position="fixed" open={open}>
            <Toolbar className={devMode ? "top-bar dev-mode" : "top-bar"} style={open ? {marginLeft:'240px'}: null}>
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawerCB}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: 'none' }) }}
                  size="large">
                <MenuIcon className="burger-button" />
              </IconButton>

              { renderLogo() }

              {quickFilter ? renderQuickFilter(): null}  


                <div className='empty-push-left'></div>

                { me ? 
                    null :
                    <>
                        { isMobile ? null : 
                            <>
                                <div className="login-link"><div onClick={()=>{onboardingCB('LOGIN')}}>{t('log in')}</div></div>
                                <div className="registration-link"><div onClick={()=>{onboardingCB('REGISTRATION')}}>{t('sign up')}</div></div>
                            </>
                        }
                        
                    </>
                }

                {   /* we do not show filter icon on profile page (or should we??) */
                    location && location.pathname === '/profile' ?
                    null :
                    <IconButton
                        className={activityFilter || periodFilter ? "filter-button has-filter":"filter-button"}
                        aria-label="filtrera"
                        onClick={toggleFilter}
                        size="large">
                        <FilterAltIcon />
                    </IconButton>

                }

                
                
                

            </Toolbar>

            <FilterDialog open={showFilterPanel} closeCB={toggleFilter}/>
          </AppBar>
);
} 

export default TopBar;