import React, { createContext, useRef, useState } from 'react';

const MedalViewContext = createContext();

export const MedalViewProvider = ({ children }) => {
    // Define refs here
    const topReportersRef = useRef([]);
    const topReportersTokenRef = useRef(null);
    const [topReporters, setTopReporters] = useState([]);
    const [showPromo, setShowPromo] = useState(false);

    // Context value
    const medalViewStore = {
        topReportersRef, 
        topReportersTokenRef,
        topReporters, setTopReporters,
        showPromo, setShowPromo
    };

    return (
        <MedalViewContext.Provider value={medalViewStore}>
            {children}
        </MedalViewContext.Provider>
    );
};

export default MedalViewContext;
