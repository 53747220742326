import React, { createContext, useRef, useState } from 'react';

const ProfileViewContext = createContext();

export const ProfileViewProvider = ({ children }) => {
    // Define states and refs here
    const [hasMoreMyReports, setHasMoreMyReports] = useState(true);
    const [activeProfileTab, setActiveProfileTab] = useState(0);
    const [myTopics, setMyTopics] = useState(null);
    const selectedMyReportRef = useRef(null);
    const profileViewPositionRef = useRef(0);

    // Context value
    const profileViewStore = {
        hasMoreMyReports, setHasMoreMyReports, 
        selectedMyReportRef, 
        activeProfileTab, setActiveProfileTab, 
        myTopics, setMyTopics,
        profileViewPositionRef
    };

    return (
        <ProfileViewContext.Provider value={profileViewStore}>
            {children}
        </ProfileViewContext.Provider>
    );
};

export default ProfileViewContext;
