import React, { useState, useRef, useEffect, useContext, useLayoutEffect, Suspense, lazy } from 'react';
import { Routes, matchPath, useLocation, Route, useNavigate } from "react-router-dom";
import { createTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import LoadingOverlay from './components/loadingOverlay';
import StoreContext from './services/storeContext';
import ReportsContext from './services/reportsContext';
import FilterContext from './services/filterContext';
import FlowViewContext from './services/flowViewContext';
import ProfileViewContext from './services/profileViewContext';
import MapContext from './services/mapContext';
import FirstTimeScreen from './components/firstTimeScreen';
import ApiService from './services/apiService'
import { InfoModal } from './services/modalService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAnalytics } from "firebase/analytics";
import MemoizedSideBar from './components/sideBar'
import TopBar from './components/topBar'
import RouteSwitch from './routes'
import { getAuth, onAuthStateChanged, getRedirectResult, signOut } from "firebase/auth";
import  HelperService, { CONST } from './services/helperService';
import AuthenticationService from './services/authenticationService';
import './App.scss';
import 'fontsource-roboto'
import cloneDeep from 'lodash.clonedeep';
import SuspenseFallback from './components/suspenseFallback';
import { ModalUserView } from './views/userView';
import UsersContext from './services/usersContext';
import MedalViewContext from './services/medalViewContext';

const ReportView = lazy(() => import('./views/singleReportView'));
const LoginView = lazy(() => import('./views/loginView'));
const RegistrationView = lazy(() => import('./views/registrationView'));


const theme = createTheme(CONST.THE_THEME);

export default function App() {
    const location = useLocation();    
    let navigate = useNavigate();
    const { i18n } = useTranslation('app');

    let { centerRef, setUserPosition } = useContext(MapContext); 
    let {setShowPromo} = useContext(MedalViewContext);
    
    let {mapReportsRef, flowReportsRef} = useContext(ReportsContext); 

    let {infoModal, setInfoModal, me, setMe, appInError, setAppInError,open, setOpen, setMediaToAdd, 
        setIsMobile, devMode, isFlutterApp, setIsFlutterApp, fcmToken, setFcmToken, setFlutterVersion, 
        setFlutterPlatform, sendFlutterNotification, setSendFlutterNotification} = useContext(StoreContext);  

    let {flowReportsTokenRef} = useContext(FlowViewContext);
    let {showUserProfile, setShowUserProfile} = useContext(UsersContext);

    let {activityFilter, periodFilter, quickFilter, previousQuickFilter, setPreviousQuickFilter,
        previousActivityFilter, setPreviousActivityFilter, previousPeriodFilter, setPreviousPeriodFilter} = useContext(FilterContext);

    let {myTopics, setMyTopics} = useContext(ProfileViewContext);

    const getViewMode = () => {
        //let vm = 'APP';
        let vm = 'INITIAL_LOAD';
        if (matchPath({ path: "/report/:id", exact: false }, location.pathname)) vm = 'SINGLE_REPORT';
        if (HelperService.localStorageItem('redirectFrom') === 'login') vm = 'LOGIN'; 
        if (HelperService.localStorageItem('redirectFrom') === 'registration') vm = 'REGISTRATION';
        if (!HelperService.localStorageItem('cookiesApproved') && vm !== 'SINGLE_REPORT') vm = 'FIRST_TIME'; 
        //console.log(`setting viewmode to ${vm} from getViewMode`);
        return vm;
    }
    
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const analytics = getAnalytics();

    const [viewMode, setViewMode] = useState(getViewMode()); // LOGIN | REGISTRATION | APP | FIRST_TIME | INITIAL_LOAD | SINGLE_REPORT
    const [onboardingErrorCode, setOnboardingErrorCode] = useState(null);
    
    // reference to the scrollable div for all long pages. Passed on to all views who need to remember their 
    // scroll position to be able to restore it when the view is closed and then opened again
    const scrollBoxRef = useRef(null);

    const [initOngoing, setInitOngoing] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);
    
    const [modalText, setModalText] = useState(['']);
    const [modalOkCallback, setModalOkCallback] = useState(() => () => { setShowInfoModal(false) });
    const [modalCancelCallback, setModalCancelCallback] = useState(null);
    // only used to signal fetching of the first page of data from the flow view, when first page is fetched 
    // a state change here in app.js forces the flowvew to redraw which is needed since the view otherwise 
    // very occasionally, when authentication token has timed out, is not redrawn.
    const [isFetchingFlowViewData, setIsFetchingFlowViewData] = useState(false);

    const [reportCreated, setReportCreated] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('isFlutter')) {
            setIsFlutterApp(true);
            if (window && window.localStorage) window.localStorage.setItem('cookiesApproved', 'ok');
            setSendFlutterNotification((m) => (m) => {sendToFlutter(m)});
            ApiService.sendFlutterNotification = sendFlutterNotification;
        }
        else {
            setIsFlutterApp(false);
        }

        let viewMd = getViewMode();
        setViewMode(viewMd);
        const loader = document.getElementById('preLoadIndicator');
        if (loader) {
            loader.style.display = 'none';
        }
        setInitOngoing(false);

        if ( window && window.localStorage && !window.localStorage.getItem('hasSeenMedalPromo')) { 
            setShowPromo(true);
            window.localStorage.setItem('hasSeenMedalPromo', 'yes');
        }

        const auth = getAuth();
        //onAuthStateChanged(auth, handleAuthStateChange(setMe, centerRef, setInitOngoing));
        let token = window.localStorage.getItem('tempToken');
        //token = "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk3OGI1NmM2NmVhYmIwZDlhNmJhOGNhMzMwMTU2NGEyMzhlYWZjODciLCJ0eXAiOiJKV1QifQ.eyJyb2xlIjoic3VwZXJhZG1pbiIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9pc2tvbGwiLCJhdWQiOiJpc2tvbGwiLCJhdXRoX3RpbWUiOjE2NzA3ODA1ODYsInVzZXJfaWQiOiJVaDM1bGZiZGZYWTIxWFFPeDJDd0h3MWV3THAxIiwic3ViIjoiVWgzNWxmYmRmWFkyMVhRT3gyQ3dIdzFld0xwMSIsImlhdCI6MTY3MDk5OTQwOCwiZXhwIjoxNjcxMDAzMDA4LCJlbWFpbCI6Im9sYUBwYXBlcmVlZC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJvbGFAcGFwZXJlZWQuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.wEPOs1eq01EDUcIDfD4Vkzs6oJvsvlDIJ1BpOUec-0qAIUzZaNVPGGZAfqTi0eLZZAFvFYwwODHv8hqV-LHCB4ksIZWIfajO7s2LPe7c8JlXdAvDb6AfL-LdYs-hRA129p5TV682awioGVySnzQr6nXKnw-RA3oy4eJqN7mGAA7GGBTR_VU37F9_hAEtEsOaaIFzFhx8U-mWTT6_X6M279oSTWondzj_B9c2j9dHEZ5meV_7FaIfIYnrDw99Gxc6Kc3TTiNntYHXbQ5zkkSGO-gjKbt0zAVceJzCzv3P8gySuFUNLhzZQneYC6zyC8YJYibH_vwC2x55d267HaADTA";
        //token = 'abc';
        if (token) {
            /* let logToken = 'null';
            if (token) logToken = token.slice(0, 6) + '...'; */
            ApiService.accessToken = token;
            handleAuthenticationCompleted();
        }
        else {
            onAuthStateChanged(auth, AuthenticationService.handleAuthStateChange(handleAuthenticationCompleted, sendFlutterNotification));
        }

        auth.onIdTokenChanged(async function(user) {
            //console.log('IN: onIdTokenChanged: ', user);

            if (user) {
                let isInRegistrationForm = false;
                if ( window && window.localStorage && 
                    window.localStorage.getItem('redirectFrom') === 'registration' && 
                    !user.emailVerified) {
                        isInRegistrationForm = true;

                }    
                if (!me && !isInRegistrationForm) {
                    const token = await user.getIdToken();
                    let existingToken = window.localStorage.getItem('tempToken');
                    if (!existingToken || existingToken !== token) {
                        window.localStorage.setItem('tempToken', token);
                        ApiService.accessToken = token;
                        handleAuthenticationCompleted();
                    }
                }
            }
          });

        
        getRedirectResult(auth).then(function (result) {
            AuthenticationService.handleRedirectResult(setViewMode, setMe, setInfoModal, setOnboardingErrorCode, location, analytics, result, fcmToken, setFcmToken).catch((error) => {
                //console.log('REDIRECT ERROR');
                //console.log(error);
                if (error.code === 'auth/account-exists-with-different-credential') {
                    //console.log('Konto existerar redan!!!');
                    setInitOngoing(false);
                    setViewMode(getViewMode());
                    setOnboardingErrorCode('auth/account-exists-with-different-credential');
                }
            });
        });  
        
        /* const loader = document.getElementById('preLoadIndicator');
        if (loader) {
            console.log('Setting style to none!!');
            loader.style.display = 'none';
        } */

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /* Called when a component wants to show the info modal via the context*/
    useEffect(() => {
        if (!infoModal) return;
        setModalText(infoModal.content);
        setModalOkCallback(infoModal.okCB);
        setModalCancelCallback(infoModal.cancelCB);
        setShowInfoModal(true);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [infoModal]);

    useEffect(() => {
        if (isSmallScreen) {
            setOpen(false);
            setIsMobile(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isSmallScreen]);

    useLayoutEffect(() => {
        if (matchPath({ path: "/report/:id", exact: false }, location.pathname)) {
            setViewMode('SINGLE_REPORT');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [viewMode]);

    useEffect(() => {
        let willReload = false;

        if (quickFilter !== previousQuickFilter) {
            setPreviousQuickFilter(quickFilter);
            willReload = true;
        }

        if (HelperService.arePeriodFiltersDifferent(periodFilter, previousPeriodFilter)) {
            if (periodFilter) setPreviousPeriodFilter(periodFilter);
            else setPreviousPeriodFilter(null);
            willReload = true;
        }
        
        if (HelperService.areStringArraysDifferent(activityFilter, previousActivityFilter)) {
            if (activityFilter) setPreviousActivityFilter(activityFilter.slice());
            else setPreviousActivityFilter(null);
            willReload = true;
        }
        if (willReload) {
            flowReportsRef.current = [];
            mapReportsRef.current = [];
            flowReportsTokenRef.current = null;
            //reloadCB();
        }
        
    //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [activityFilter, periodFilter, quickFilter]); 

    

    /*  Send a message to the Flutter app, dataString is a stringified
        json object {command, data} where command is one of the commands supported by the 
        flutter app: JUST_LOG, CHECK_NOTIFICATION_PERMISSION, UPLOAD_PHOTOS, COPY_TO_CLIPBOARD, LOGOUT_USER, 
        WEB_APP_INITIALIZATION_COMPLETED, LAUNCH_URL, LAUNCH_SOCIAL_APP, GET_USER_POSITION

        Views are calling sendFlutterNotification which triggers the sendtoFlutter method
    */
    // eslint-disable-next-line no-unused-vars 
    const sendToFlutter = (dataString) => {
        if (window.messageHandler) window.messageHandler.postMessage(dataString);
    }



    window.flutterApi.setNotificationToken = async (token) => {
        setFcmToken(token);
        try {
            await ApiService.callApi(ApiService.updateFcmToken, [token], 'updateFcmToken');     
        } catch (error) {
            console.log('Exception in flutterApi.setNotificationToken');
        }
    }

    // level = 'ALL' | 'NONE'
    window.flutterApi.setNotificationLevel = (level) => {
        if (level === 'NONE' && isFlutterApp && myTopics && !myTopics.includes('NONE__DIRECT')) {
            sendToFlutter('{"command": "JUST_LOG", "data":"WEBAPP: unsubscribing from all topics"}');
            ApiService.callApi(ApiService.unsubscribeMeFromAllTopics, [{fcmToken: fcmToken}], 'unsubscribeMeFromAllTopics').then((result)=>{
                HelperService.processAndSetTopics(result, setMyTopics);
            });
        }
        
    }



    window.flutterApi.setBearerToken = (token) => {
        // since all flutter param comes as strings we must manually take care of the null case
        if (token === 'null') token = null;
        ApiService.accessToken = token;        
        /* let logToken = 'null';
        if (token) logToken = token.slice(0, 6) + '...';
        let log = `webapp 78: bearer token set to ${logToken}`;
        sendToFlutter('{"command": "JUST_LOG", "data":"' + log + '"}');  */
        handleAuthenticationCompleted();        
    }

    window.flutterApi.setSignedinUser = (user) => {
        if (user && user.fcmToken && user.fcmToken !== fcmToken) setFcmToken(user.fcmToken);
        setMe(user);        
    }

    /* Trigger a redraw by adding a temporary prop to the signed-in user
    */
    window.flutterApi.triggerRedraw = () => {
        if (me) {
            let meCopy = cloneDeep(me);
            meCopy.redrawTrigger = Date.now();
            setMe(meCopy);
        }
    }

    window.flutterApi.setLanguage = (lang) => {
        i18n.changeLanguage(lang);        
    }

    window.flutterApi.setFlutterVersion = (version) => {
        setFlutterVersion(version);        
    }

    /* Flutter app can call justLog which will log the provided data in the webapps persistent 
       store. 
       Webapp can send a JUST_LOG command to flutter which will log the command payload in the flutter console. 
    */
    window.flutterApi.justLog = (data) => {
        //window.localStorage.setItem('mapCenter');
        let existingLog = window.localStorage.getItem('debugLog');
        if (!existingLog) {
            let log = ['LOG STARTED: ' + (new Date()).toISOString()];
            log.push(data);
            window.localStorage.setItem('debugLog', JSON.stringify([data]));
        }
        else {
            existingLog = JSON.parse(existingLog);
            let timestampedEntry = (new Date()).toISOString() + '$*$' + data;             
            existingLog.push(timestampedEntry);
            window.localStorage.setItem('debugLog', JSON.stringify(existingLog));
        }
        let wtlData = 'WROTE TO LOG: ' + data;
        let payload = '{"command": "JUST_LOG", "data":"' + wtlData + '"}';
        sendToFlutter(payload);

    }

    window.flutterApi.showReport = (reportId) => {
        console.log(`Will show report: ${reportId}`);
        navigate('/report/'+reportId);        
    }

    window.flutterApi.setFilePickerResult = (stringifiedFiles) => {
        try {
            let files = JSON.parse(stringifiedFiles);
            const imageFiles = [];

            if (files.length > 10) files = files.slice(0, 10);

            files.forEach((base64String, index) => {
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "image/jpeg" }); // Adjust the MIME type if needed

                // Create a File object. You might want to generate a filename as well
                const imageFile = new File([blob], `image_${index}.jpg`, { type: "image/jpeg" });
                const src = URL.createObjectURL(imageFile)
                imageFiles.push({ file: imageFile, src: src });
            });

            setMediaToAdd(imageFiles);
        } catch (error) {
            console.log(error);
        }
    }  

    // iphone, android
    window.flutterApi.setFlutterPlatform = (platform) => {
        setFlutterPlatform(platform);        
    }

    window.flutterApi.showError = (type, error) => {
        if (type === 'POSITIONING_ERROR') {
            setUserPosition({lat:null, lng:null, error: error});
        }        
    }

    window.flutterApi.updateCurrentPosition = (position) => {
        let parts = position.split(',');        
        if (parts.length === 2) {
            let lat = parseFloat(parts[0].substring(1 + parts[0].indexOf(':')));
            let lng = parseFloat(parts[1].substring(1 + parts[1].indexOf(':')));
            if (!isNaN(lat) && !isNaN(lng)) {
                console.log('****** SETTING USER POSITION FROM FLUTTER: ' + lat + ', ' + lng);
                setUserPosition({lat:lat, lng:lng});
                //setIsPositioning(null);
                
            }
        }
    }
    

    

    


    /* Called when authentication-token is in place
    */
    const handleAuthenticationCompleted = async () => {
        //console.log('AUTHENTICATION COMPLETE, GETTING USER-ME, viewMode = ', viewMode);
        if (isFlutterApp) {
            let log = `WEBAPP: Authentication complete, viewMode = ${viewMode}`;
            sendToFlutter('{"command": "JUST_LOG", "data":"' + log + '"}');
        }
        //console.log('me', me);
        let userMe = null;
            try {
                userMe = await ApiService.callApi(ApiService.getMe, [{fcmToken: fcmToken}], 'getMe');
            } catch (error) {
                console.log(error);
                setOnboardingErrorCode('38');
                //sendToFlutter('{"command": "JUST_LOG", "data":"WEBAPP: Point A"}');
            }

            if (userMe && (userMe.emailVerified || 
                (userMe.provider === 'facebook.com') || userMe.provider === 'apple.com' )) {
                if (userMe.fcmToken && userMe.fcmToken !== fcmToken) setFcmToken(userMe.fcmToken);
                setMe(userMe);
                window.localStorage.removeItem('redirectFrom');
                setOnboardingErrorCode(null);
                setViewMode('APP');
                //sendToFlutter('{"command": "JUST_LOG", "data":"WEBAPP: Point B"}');
            }
            else if (userMe && !userMe.emailVerified) {
                //sendToFlutter('{"command": "JUST_LOG", "data":"WEBAPP: Point C"}');
                if (isFlutterApp) sendFlutterNotification('{"command": "LOGOUT_USER"}');
                else {
                    const auth = getAuth();
                    ApiService.accessToken = null;
                    window.localStorage.removeItem('tempToken');
                    setMe(null);
                    await signOut(auth);
                    navigate('/');
                }
                setOnboardingErrorCode('email-not-verified');
            }
            else if (!userMe) {
                //console.log('NO-USER');
                // sendToFlutter('{"command": "JUST_LOG", "data":"WEBAPP: Point D"}');
                //    setOnboardingErrorCode('no-user');
                if (me) setMe(null); // if flutter app logs out we need to set me to null
                if (window && window.localStorage && window.localStorage.getItem('redirectFrom') === 'login') {
                    setOnboardingErrorCode('no-user');
                }
                else {
                    if (viewMode !== 'FIRST_TIME'){
                        //console.log('SET ViewMode TO APP 1', viewMode);
                        setViewMode('APP');
                    }
                }
                window.localStorage.removeItem('redirectFrom');
                window.localStorage.removeItem('tempToken');
            } 
            let oldCenter = window.localStorage.getItem('mapCenter');
            if (oldCenter) centerRef.current = JSON.parse(oldCenter);
            setInitOngoing(false);
    }

    const handleInfoModalOk = () => {
        if (modalOkCallback) modalOkCallback();
        setShowInfoModal(false);
        if (appInError)  setAppInError(null);
    }

    const handleDevModeCB = (command) => {
        if (command === 'SHOW_LOG') {
            let existingLog = window.localStorage.getItem('debugLog');
            if (existingLog) existingLog = JSON.parse(existingLog);
            setInfoModal({content: [existingLog]});
        }
        if (command === 'DELETE_LOG') {
            window.localStorage.removeItem('debugLog');
        }
    }

    const handleInfoModalCancel = () => {
        if (modalCancelCallback) modalCancelCallback();
        setShowInfoModal(false);
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };


    /* called for login and registration */
    const handleOnboarding = (viewMode) => {
        setOnboardingErrorCode(null);
        if (isFlutterApp) { 
            // this should not happen since webapp menu should not be visible
            // without a logged in user when running in app mode (it will always 
            // be covered by the native login view) but if it is visible due to a bug
            // we want the user to be able to login via flutter
            let payload = '{"command": "LOGIN_USER"}';
            sendToFlutter(payload);
        }
        else {
            setViewMode(viewMode);
        }
    };

    const handleOnboardingCanceled = () => {  
        setOnboardingErrorCode(null);
        window.localStorage.removeItem('redirectFrom');    
        //console.log('1: setting viewmode to APP!!');
        setViewMode('APP');
    }; 

    const handleSingleReportClose = () => {
        if (!HelperService.localStorageItem('cookiesApproved')) setViewMode('FIRST_TIME');
        else setViewMode('APP');
        navigate('/');
    }

    const handleForgotPw = () => {
        setViewMode('APP');
        navigate('/forgotpw');
    }


    //const provider = (provider, props = {}) => [provider, props];

    const ProviderComposer = ({ providers, children }) => {
        for (let i = providers.length - 1; i >= 0; --i) {
            const [Provider, props] = providers[i];
            children = <Provider {...props}>{children}</Provider>
        }
        return children;
    }

    const getMainClasses = () => {
        let classes = 'main-section';
        if (open) classes += ' open-sidebar';
        else classes += ' closed-sidebar';
        //if (mainSectionOnTop) classes += ' main-on-top';
        if (location.pathname === '/map') {
            classes += ' map';
        }
        return classes;
    }

    const renderFirstTimeScreen = () => {
        return (
            <FirstTimeScreen closeCB={handleOnboardingCanceled} toRegistrationCB={()=>{handleOnboarding('REGISTRATION')}} toLoginCB={()=>{handleOnboarding('LOGIN')}}/>
        );
    }
    
    const renderLogin = () => {
        return (
            <LoginView loginCanceledCB={handleOnboardingCanceled} forgotPwCB={handleForgotPw} toRegistrationCB={()=>{handleOnboarding('REGISTRATION')}} onboardingErrorCode={onboardingErrorCode}/>
        );
    }

    const renderRegistration = () => {
        return (
            <RegistrationView registrationCanceledCB={handleOnboardingCanceled} toLoginCB={()=>{handleOnboarding('LOGIN')}} onboardingErrorCode={onboardingErrorCode}/>
        );
    }

    const handleSingleReportViewModeChange = (vm) => {
        setViewMode(vm);
    }

    const renderSingleReport = () => {
        return (
            <div>
                <Routes>
                    <Route path="/report/:reportId" element={<ReportView closeViewCB={handleSingleReportClose} setAppViewMode={handleSingleReportViewModeChange}/>}/>
                </Routes>
            </div>
        );
    }


    const renderApp = () => {
        return (
            <>
                {!initOngoing && !appInError ?
                    <div>
                        <div >
                            <div id="scrollBox" ref={scrollBoxRef} style={{ display: 'flex', height: '100vh', overflowY: 'scroll' }} >
                                <CssBaseline />
                                <TopBar open={open} toggleDrawerCB={toggleDrawer} onboardingCB={handleOnboarding} />
                                <MemoizedSideBar window={window} open={open} toggleDrawerCB={toggleDrawer} onboardingCB={handleOnboarding} devMode={devMode} devModeCB={handleDevModeCB} />
                                <Main id="mainContainer" className={getMainClasses()} open={isSmallScreen ? true : open} >
                                    <DrawerHeader />
                                    <RouteSwitch me={me} scrollBoxRef={scrollBoxRef} singleReportClose={handleSingleReportClose} 
                                    setAppViewMode={handleSingleReportViewModeChange} isFetchingFlowViewData={isFetchingFlowViewData} 
                                    setIsFetchingFlowViewData={setIsFetchingFlowViewData}
                                    reportCreated={reportCreated} setReportCreated={setReportCreated}
                                    ></RouteSwitch> 
                                </Main>
                            </div>
                        </div>
                        {(showInfoModal) ?
                            
                            <InfoModal text={modalText} sidebarOpen={open} infoModalOkCB={handleInfoModalOk} customClass={devMode ? 'dev-mode-info-modal' : null}
                            infoModalCancelCB={(modalCancelCallback) ? handleInfoModalCancel : null} />
                            : null}

                    </div>

                    :

                    <>
                        {initOngoing ?
                            <LoadingOverlay text={''} />
                            :
                            <>{appInError ?
                                <InfoModal text={appInError} sidebarOpen={open} infoModalOkCB={handleInfoModalOk} infoModalCancelCB={null} />
                                : <div>???</div>}</>
                        }
                    </>
                }
            </>
        );
    }

    const renderInitialLoad = () => {
        //return null;
         return (
            <>  
                <SuspenseFallback/>
                {/* <LoadingOverlay text={'Mammamm'}/> */} 
            </>
        ); 
    }

    //console.log('RENDERING APP with viewMode:', viewMode);
    return (
        <Suspense fallback={<><SuspenseFallback/></>}>
        <ProviderComposer
            providers={[]}>
                    { viewMode === 'FIRST_TIME' ? renderFirstTimeScreen() : null } 
                    { viewMode === 'LOGIN' ? renderLogin() : null } 
                    { viewMode === 'REGISTRATION' ? renderRegistration() : null } 
                    { viewMode === 'APP' ? renderApp() : null } 
                    { viewMode === 'SINGLE_REPORT' ? renderSingleReport() : null } 
                    { viewMode === 'INITIAL_LOAD' ? renderInitialLoad() : null }  

                    { showUserProfile ?
                        <ModalUserView userRef={showUserProfile} 
                            handleUserProfileClose={()=>{setShowUserProfile(null)}} 
                            sidebarOpen={open}
                            showUserProfile={showUserProfile}></ModalUserView>
                    : null
                    }
        </ProviderComposer>
        </Suspense>
    ); 


}


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        //padding: theme.spacing(3),
        padding: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        //marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


