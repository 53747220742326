import React, { createContext, useRef, useState } from 'react';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    // Define states and refs here
    const myReportsPositionRef = useRef(0); 
    const newReportDataRef = useRef({}); 
    const [infoModal, setInfoModal] = useState(null);
    const [me, setMe] = useState(null);
    const [mediaToAdd, setMediaToAdd] = useState([]);
    const [appInError, setAppInError] = useState(null);
    const [open, setOpen] = useState(true);
    const [testModeData, setTestModeData] = useState(null);
    const [dashboardData, setDashboardData] = useState(null);
    const [activeFilter, setActiveFilter] = useState(null);
    const [previousFilter, setPreviousFilter] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isFlutterApp, setIsFlutterApp] = useState(null);
    const [sendFlutterNotification, setSendFlutterNotification] = useState(null);
    const [fcmToken, setFcmToken] = useState(null);
    const [flutterVersion, setFlutterVersion] = useState(null);
    const [flutterPlatform, setFlutterPlatform] = useState(null);
    const [drawerStates, setDrawerStates] = useState({});
    const [devMode, setDevMode] = useState(false);

    // Context value
    const store = {
        myReportsPositionRef, newReportDataRef,
        infoModal, setInfoModal, me, setMe, mediaToAdd, setMediaToAdd, 
        appInError, setAppInError, open, setOpen, testModeData, setTestModeData,
        dashboardData, setDashboardData, activeFilter, setActiveFilter, previousFilter, setPreviousFilter,
        isMobile, setIsMobile, devMode, setDevMode,
        isFlutterApp, setIsFlutterApp, fcmToken, setFcmToken, flutterVersion, setFlutterVersion, flutterPlatform, setFlutterPlatform,
        sendFlutterNotification, setSendFlutterNotification, drawerStates, setDrawerStates
    };

    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export default StoreContext;
