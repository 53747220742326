import React, {useContext}  from 'react';
import Dialog from '@mui/material/Dialog';
import StoreContext from '../services/storeContext';
import HelperService from '../services/helperService';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import UtekollLogo from './utekollLogo';
import '../styles/registrationView.scss'
import '../styles/firstTimeScreen.scss'
 

function FirstTimeScreen({toRegistrationCB, toLoginCB, closeCB}) {
    const { t, i18n } = useTranslation('app');
    const ute = !HelperService.isIskoll();
    let { isFlutterApp, sendFlutterNotification} = useContext(StoreContext);

    const handleClose = () => {
        window.localStorage.setItem('cookiesApproved', 'ok');
        closeCB();
    }

    const handleLogin = () => {
        window.localStorage.setItem('cookiesApproved', 'ok');
        toLoginCB();
    }

    const handleRegistration = () => {
        window.localStorage.setItem('cookiesApproved', 'ok');
        toRegistrationCB();
    }

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const renderLangButton = () => {
        if (i18n.language === 'en') {
            return (
                <Button className={'img-text-btn'} style={{marginLeft:'auto', marginRight:'auto'}} 
                        onClick={()=>{changeLanguage('sv')}} variant="outlined" disableElevation>
                    <div className="btn-img sv"></div><span>Byt till svenska</span>
                </Button>
            );
        }

        return (
            <Button className={'img-text-btn'} style={{marginLeft:'auto', marginRight:'auto'}} 
                    onClick={()=>{changeLanguage('en')}} variant="outlined" disableElevation>
                <div className="btn-img en"></div><span>Change to English</span>
            </Button>
        );
    }
  
    return (
        <Dialog className="onboarding-root" fullScreen open={true} onClose={()=>{}} >
        <div className="onboarding-view first-time-screen">
            {ute ? <UtekollLogo/> : <div className="iskoll-logo"><div/><div/></div> }
            <h1>{t('hi and welcome to iskoll', { iskoll: ute? 'Utekoll':'Iskoll'})}</h1>

            <div style={{display:'flex'}}>
                {renderLangButton()}
            </div>

            <p>{t('iskoll is an app where you ...', { iskoll: ute? 'Utekoll':'Iskoll'})}</p>

            <p>{t('to get full functionality we recommend ...')}</p>
            <div style={{display:'flex'}}>
                    <Button style={{marginLeft:'auto', marginRight:'auto'}} onClick={()=>{handleRegistration()}} variant="outlined" disableElevation>{t('to sign up')}</Button>
            </div>

            <p className='login-text'>{t('if you already signed up go ahead and log in')}</p>
            <div style={{display:'flex'}}>
                    <Button style={{marginLeft:'auto', marginRight:'auto'}} onClick={()=>{handleLogin()}} variant="outlined" disableElevation>{t('to login')}</Button>
            </div>

            <p className='login-text'>{t('if you dont want to sign up right now ...')}</p>
            <div style={{display:'flex'}}>
                    <Button style={{marginLeft:'auto', marginRight:'auto'}} onClick={()=>{handleClose()}} variant="outlined" disableElevation>{t('continue as guest')}</Button>
            </div>

            <p className='cookie-text'>{t('to make iskoll fully functional we use cookies', { iskoll: ute? 'Utekoll':'Iskoll'})} <span className="hyperlink" onClick={()=>HelperService.openUrl('https://www.iskoll.se/terms', isFlutterApp, sendFlutterNotification)}>{t('cookie policy')}</span>.</p>

        </div>
        <IconButton
            className="close-btn"
            edge="start"
            color="inherit"
            onClick={()=>{handleClose()}}
            aria-label="close"
            size="large">
              <CloseIcon />
            </IconButton>
        </Dialog>
    );

}
//

export default FirstTimeScreen;